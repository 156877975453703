/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
} from '@chakra-ui/react';
import { BsStarFill, BsStarHalf } from 'react-icons/bs';
import { FaArrowRight } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from '@choc-ui/chakra-autocomplete';
import { RangeDatepicker } from '../RangeDatePicker';
import { useSearch } from '../../providers/SearchProvider';
import { calcDate, selectTypeDstn, typeDestination } from '../../utils/hotelsSearch';
import CounterBox from '../CounterBox';
import { nationalitys } from '../../utils/nationalitys';
import { useLanguage } from '../../providers/LanguageProvider';
import { translates } from '../../utils/languague';

export default function Filters(hotels) {
  const {
    nationality,
    setNationality,
    rooms,
    mnPrice,
    setMnPrice,
    mxPrice,
    setMxPrice,
    nNights,
    setNNights,
    roomOnly,
    sR,
    setSR,
    setRooms,
    getSearchHotels,
    destinations,
    destinationId,
    setDestinationID,
    hotelID,
    setHotelID,
    setSearch,
    selectedDates,
    setSelectedDates,
    loading,
    loadingD,
    onChanged,
    setHotels,
    filterPrice,
    setFilterPrice,
    isFirstSrc,
    setIsFirstSrc,
    search,
  } = useSearch();

  const { language } = useLanguage();
  const [majorDataHotel, setMajorDataHotel] = useState(hotels || []);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [verifySrc, setVerifySrc] = useState('');
  const [verifySrcH, setVerifySrcH] = useState('');
  const [advFilters, setAdvFilters] = useState(true);
  const [isStranger, setIsStranger] = useState(false);
  const [categories, setCategorties] = useState([1, 2, 3, 4, 5]);
  const [initialMnPrice, setInitialMnPrice] = useState(null);
  const [initialMxPrice, setInitialMxPrice] = useState(null);
  const [changeCategories, setChangeCategories] = useState(false);
  const [changePrices, setChangePrices] = useState(false);

  // Novos filtros
  const [filterHasBreakfast, setFilterHasBreakfast] = useState(null);
  const [filterAllInclusive, setFilterAllInclusive] = useState(null);
  const [filterCancellationPolicy, setFilterCancellationPolicy] = useState(null);
  const [filterFullBoard, setFilterFullBoard] = useState(null);
  const [filterHalfBoard, setFilterHalfBoard] = useState(null);
  const [filterSpecialOffer, setFilterSpecialOffer] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const [filterTopHotel, setFilterTopHotel] = useState(null);

  // Show Buttons Apply - Estados separados para cada filtro
  const [showApplyFullBoard, setShowApplyFullBoard] = useState(false);
  const [showApplyHalfBoard, setShowApplyHalfBoard] = useState(false);
  const [showApplyTopHotel, setShowApplyTopHotel] = useState(false);
  const [showApplySpecialOffer, setShowApplySpecialOffer] = useState(false);
  const [showApplyBreakfast, setShowApplyBreakfast] = useState(false);
  const [showApplyAllInclusive, setShowApplyAllInclusive] = useState(false);
  const [showApplyCancellation, setShowApplyCancellation] = useState(false);

  const toggleCheckbox = (currentValue, setterFunction, showApplySetter) => {
    console.log(`Current value before toggle: ${currentValue}`);
    const newValue = currentValue === true ? false : currentValue === false ? null : true;
    setterFunction(newValue);
    console.log(`New value after toggle: ${newValue}`);
    if (showApplySetter) showApplySetter(true);
  };
  // Defina a função `applyAdvancedFilters` ANTES de usá-la
  const applyAdvancedFilters = hotelsList => {
    return hotelsList.filter(hotel =>
      hotel.Rooms.some(room => {
        return (
          (filterHasBreakfast === null || room.HasBreakfast === filterHasBreakfast) &&
          (filterFullBoard === null || room.FullBoard === filterFullBoard) &&
          (filterHalfBoard === null || room.HalfBoard === filterHalfBoard) &&
          (filterTopHotel === null || room.TopHotel === filterTopHotel) &&
          (filterSpecialOffer === null || room.SpecialOffer === filterSpecialOffer) &&
          (filterAllInclusive === null || room.AllInclusive === filterAllInclusive) &&
          (filterCancellationPolicy === null || room.CancellationPolicy === filterCancellationPolicy) &&
          (selectedServices.length === 0 || selectedServices.every(service => room.Services?.includes(service))) &&
          (selectedCustomFields.length === 0 || selectedCustomFields.every(field => room.CustomFields?.includes(field)))
        );
      }),
    );
  };

  useEffect(() => {
    if (localStorage.getItem('hotelStaticData')) {
      setMajorDataHotel(JSON.parse(localStorage.getItem('hotelStaticData')));
    }
  }, [localStorage.getItem('hotelStaticData')]);

  useEffect(() => {
    setNNights(calcDate(selectedDates));
  }, [selectedDates]);

  useEffect(() => {
    setSR(rooms);
  }, [rooms]);

  useEffect(() => {
    setFilterPrice('asc');
  }, [loading]);

  useEffect(() => {
    if (filterPrice !== 'asc') {
      setFilterPrice('asc');
    }
  }, [setMajorDataHotel]);

  useEffect(() => {
    const fetchData = async () => {
      if (hotels?.hotels?.Hotels !== undefined) {
        const mapHotels = hotels?.hotels?.Hotels.map(item => item);
        const results = mapHotels?.filter(el => el?.Name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
        const notResults = mapHotels?.filter(el => !el?.Name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));

        // Aplica os filtros de café da manhã, all-inclusive, etc.
        const updatedHotels = {
          Hotels: [...results, ...notResults],
        };

        const filteredHotels = applyAdvancedFilters(updatedHotels.Hotels);
        await setHotels(() => ({ Hotels: filteredHotels }));
      }
    };

    fetchData();
  }, [searchTerm, filterHasBreakfast, filterAllInclusive, filterCancellationPolicy]);

  useEffect(() => {
    if (localStorage.getItem('hotelStaticData')) {
      setMajorDataHotel(JSON.parse(localStorage.getItem('hotelStaticData')));
    }
  }, [localStorage.getItem('hotelStaticData')]);

  useEffect(() => {
    setNNights(calcDate(selectedDates));
  }, [selectedDates]);

  useEffect(() => {
    setSR(rooms);
  }, [rooms]);

  useEffect(() => {
    setFilterPrice('asc');
  }, [loading]);

  useEffect(() => {
    if (filterPrice !== 'asc') {
      setFilterPrice('asc');
    }
  }, [setMajorDataHotel]);

  const groupByTypes = item => {
    const isType = destination => destination?.type === item;
    return destinations?.filter(isType);
  };

  const inputReference = useRef(null);
  const minRef = useRef(null);
  const maxRef = useRef(null);

  const handleFocus = item => {
    inputReference.current.focus({ focusVisible: false });
    inputReference.current.value = `${item}`;
  };

  const handleUpdate = item => {
    minRef.current.value = `${item[0]}`;
    maxRef.current.value = `${item[1]}`;
  };

  const mapping = [0];

  const filterCategories = () => {
    if (hotels?.hotels?.Hotels != undefined) {
      const mapHotels = hotels?.hotels?.Hotels.map(item => item);
      const filter = mapHotels?.filter(
        c => Math.floor(parseInt(c?.Category, 10)) == categories?.filter(x => x == Math.floor(c?.Category), 10),
      );
      return filter;
    }
    return [];
  };

  const delOrAddCategorie = item => {
    setChangeCategories(true);
    const exists = categories?.filter(c => c == item);
    if (exists.length > 0) {
      setCategorties(current => current.filter(element => element !== item));
    } else {
      setCategorties(old => [...old, item]);
      getSearchHotels({
        id: destinationId,
        hotelID: hotelID != 0 ? hotelID : null,
        minimunPrice: mnPrice,
        maxPrice: mxPrice,
        numNights: nNights,
        roomsOnly: roomOnly,
        sRooms: sR,
        nation: nationality,
      });
    }
  };

  useEffect(() => {
    filterByCategories();
  }, []);

  const filterByCategories = _ => {
    const funcUpdate = async () => {
      await filterCategories();
      await setHotels(old => ({ ...old, Hotels: filterCategories() }));
    };
    const exec = async () => {
      await funcUpdate();
    };
    exec();
  };

  const filterMinOrMaxPrice = async (min, max) => {
    await setHotels(() => ({
      ...majorDataHotel,
      Hotels: majorDataHotel?.Hotels?.filter(item => {
        if (item?.Rooms[0]?.TotalSellingPrice?.Value >= min && item?.Rooms[0]?.TotalSellingPrice?.Value <= max) {
          return item;
        }
      }),
    }));
    await setFilterPrice(filterPrice === 'asc' ? 'desc' : 'asc');
  };

  const orderForPrice = async (min, max) => {
    await filterMinOrMaxPrice(min, max);

    await setFilterPrice(filterPrice !== 'desc' ? 'asc' : 'desc');
    await setFilterPrice(filterPrice !== 'asc' ? 'desc' : 'asc');
    setSearchTerm('');
  };

  const verifyFilterPrice = async () => {
    if (filterPrice === '') {
      await setFilterPrice('desc');
    }
    if (filterPrice === 'asc') {
      await setHotels(old => ({
        ...old,
        Hotels: hotels?.hotels?.Hotels?.sort(
          (a, b) => a?.Rooms[0]?.TotalSellingPrice?.Value - b?.Rooms[0]?.TotalSellingPrice?.Value,
        ),
      }));
    } else {
      await setHotels(old => ({
        ...old,
        Hotels: hotels?.hotels?.Hotels?.sort(
          (a, b) => b?.Rooms[0]?.TotalSellingPrice?.Value - a?.Rooms[0]?.TotalSellingPrice?.Value,
        ),
      }));
    }
  };

  const handleApplyFilters = () => {
    if (hotels?.hotels?.Hotels) {
      const filteredHotels = applyAdvancedFilters(hotels.hotels.Hotels);
      setHotels({ Hotels: filteredHotels });
      // Resetando a visibilidade do botão após aplicação para todos os novos filtros
      setShowApplyFullBoard(false);
      setShowApplyHalfBoard(false);
      setShowApplyTopHotel(false);
      setShowApplySpecialOffer(false);
      setShowApplyBreakfast(false);
      setShowApplyAllInclusive(false);
      setShowApplyCancellation(false);
    } else {
      console.error('hotels.hotels.Hotels is not an array:', hotels?.hotels?.Hotels);
    }
  };
  const handleChangeHotel = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (hotels?.hotels?.Hotels !== undefined) {
        // Mapeando hotéis para tratamento
        const mapHotels = hotels.hotels.Hotels.map(item => item);

        // Filtrando hotéis baseados no termo de busca
        const results = mapHotels.filter(el => el.Name.toLowerCase().includes(searchTerm.toLowerCase()));
        const notResults = mapHotels.filter(el => !el.Name.toLowerCase().includes(searchTerm.toLowerCase()));

        // Calculando preços mínimos e máximos para o slider de preços
        const minHotelPrice = Math.min(...mapHotels.map(hotel => hotel.Rooms[0].TotalSellingPrice.Value));
        const maxHotelPrice = Math.max(...mapHotels.map(hotel => hotel.Rooms[0].TotalSellingPrice.Value));

        setInitialMnPrice(minHotelPrice || 10);
        setInitialMxPrice(maxHotelPrice || 999999);
        minRef.current.value = minHotelPrice;
        maxRef.current.value = maxHotelPrice;

        // Extrai Services e CustomFields únicos de todos os quartos
        const allServices = new Set();
        const allCustomFields = new Set();
        mapHotels.forEach(hotel => {
          hotel.Rooms.forEach(room => {
            if (room.Services) {
              room.Services.forEach(service => allServices.add(service));
            }
            if (room.CustomFields) {
              room.CustomFields.forEach(field => allCustomFields.add(field));
            }
          });
        });

        setSelectedServices(Array.from(allServices));
        setSelectedCustomFields(Array.from(allCustomFields));

        // Combinando os resultados de busca
        const updatedHotels = {
          Hotels: [...results, ...notResults],
        };

        // Aplicando filtros adicionais e configurando os hotéis filtrados no estado
        const filteredHotels = applyAdvancedFilters(updatedHotels.Hotels);
        await setHotels(() => ({ Hotels: filteredHotels }));
      }
    };

    fetchData();
  }, [
    verifySrcH,
    searchTerm,
    loading,
    destinationId,
    hotelID,
    filterHasBreakfast,
    filterAllInclusive,
    filterCancellationPolicy,
    selectedServices,
    selectedCustomFields,
  ]);

  useEffect(() => {
    verifyFilterPrice();
  }, [filterPrice, categories, loading]);

  const applyCategoryFilters = () => {
    filterByCategories(); // Aplica os filtros de categoria
    setChangeCategories(false); // Reseta o estado após aplicar
  };

  return (
    <Box
      display="flex"
      flexDir={['column', 'column']}
      minH={['400px', '800px']}
      align="center"
      w={['94%', '100%', '30%']}
      mb={['10px', '50px']}
    >
      <Box
        w="100%"
        display="flex"
        flexDir={['column', 'column']}
        bg="theme.white"
        borderRadius="8px"
        borderWidth={1}
        p={['10px', '20px']}
        borderColor="gray.300"
        minH="400px"
      >
        {/* TO DO: RESOLVER PROBLEMA DE RENDER PARA UNIFICAR */}
        <Box>
          <Text fontSize="14px" fontWeight="medium" textAlign="start" mb="10px">
            {translates(language)?.searchbox?.default?.item1}
          </Text>
          <InputGroup zIndex={4}>
            <AutoComplete freeSolo emptyState="" closeOnBlur onSuspend={false}>
              <AutoCompleteInput
                value={inputReference?.current?.value}
                ref={inputReference}
                focusBorderColor={(verifySrc != '' || verifySrc?.length > 20) && 'red'}
                borderColor={verifySrc != '' || verifySrc?.length > 20 ? 'red' : 'gray.200'}
                placeholder={isFirstSrc ? search : translates(language)?.searchbox?.default?.item2}
                onFocus={() => setIsFirstSrc(false)}
                onChange={async e => {
                  setSearch(e?.target?.value);
                  if (destinations !== [] && (await e?.target?.value?.length) >= 3) {
                    onChanged(e?.target?.value);
                    setVerifySrc('');
                  } else {
                    setVerifySrc(translates(language)?.filters?.item24);
                  }
                }}
              />
              <AutoCompleteList>
                <Box display="flex" alignSelf="center" alignItems="center" justifyContent="flex-start">
                  {destinations?.length == 0 && !loadingD && translates(language)?.searchbox?.default?.item14}
                  {loadingD ? translates(language)?.searchbox?.default?.item13 : ''}
                </Box>

                {mapping?.map(_ => (
                  <div key="mapping">
                    {groupByTypes('Multi-Region (within a country')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'Multi-Region (within a country')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('Multi-Region (within a country')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`'Multi-Region (within a country' - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                    {groupByTypes('Multi-City (Vicinity)')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'Multi-City (Vicinity)')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('Multi-City (Vicinity)')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`'Multi-City (Vicinity)' - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                    {groupByTypes('City')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'City')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('City')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`City - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                    {groupByTypes('Neighborhood')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'Neighborhood')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('Neighborhood')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`Neighborhood - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                    {groupByTypes('Airport')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'Airport')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('Airport')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`Airport - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                    {groupByTypes('Point of Interest')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'Point of Interest')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('Point of Interest')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`Point of Interest - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                    {groupByTypes('Point of Interest Shadow')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'Point of Interest Shadow')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('Point of Interest Shadow')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`Point of Interest Shadow - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                    {groupByTypes('Hotel')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'Hotel')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('Hotel')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`Hotel - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                    {groupByTypes('Train')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'Train')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('Train')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`Train - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                    {groupByTypes('Country')?.length > 0 && (
                      <Box alignItems="center" justifyContent="flex-start">
                        <Text fontWeight="medium" ml="8px">
                          {selectTypeDstn(language, 'Country')}
                        </Text>
                      </Box>
                    )}
                    {groupByTypes('Country')?.map((h, idx) => (
                      <Flex
                        p="8px"
                        key={`Country - ${idx}`}
                        _hover={{ backgroundColor: '#F5F5F5', cursor: 'pointer' }}
                        onClick={() => {
                          setSearch(`${h?.name[language]}`);
                          setDestinationID(h?.destinationId);
                          setHotelID(h?.hotelId);
                          handleFocus(`${h?.name[language]}`);
                        }}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Box display="flex" flexDir="row" alignItems="center" justifyContent="flex-start">
                          <Box>{typeDestination(h?.type)}</Box>
                          <Text fontSize="14px">{`${h?.name[language]}`}</Text>
                        </Box>
                      </Flex>
                    ))}
                  </div>
                ))}
              </AutoCompleteList>
            </AutoComplete>
            <InputRightElement
              pointerEvents="none"
              // eslint-disable-next-line react/no-children-prop
              children={<BiSearch color="gray.300" />}
            />
          </InputGroup>
          {verifySrc != '' && (
            <Box mt="10px" align="start">
              <Text color="red">{verifySrc}</Text>
            </Box>
          )}
          <Box display="flex" w="100%" alignContent="flex-start" mt="10px" mb="10px">
            <Checkbox onChange={() => setIsStranger(!isStranger)} colorScheme="red">
              {translates(language)?.searchbox?.default?.item3}
            </Checkbox>
          </Box>
          {isStranger && (
            <>
              <Text fontSize="14px" fontWeight="bold" textAlign="start" mb="10px">
                Nacionalidade
              </Text>
              <InputGroup>
                <AutoComplete emptyState={loadingD ? '' : 'Nenhum resultado encontrado'} openOnFocus onSuspend={false}>
                  <AutoCompleteInput placeholder="Busque sua nacionalidade" />
                  <AutoCompleteList>
                    {nationalitys &&
                      nationalitys?.map((nt, idx) => (
                        <AutoCompleteItem
                          alignItems="center"
                          onClick={() => setNationality(nt?.iso2Code)}
                          key={`option-${idx}`}
                          value={`${nt?.name}`}
                          textTransform="capitalize"
                        >
                          {`${nt?.name}`}
                        </AutoCompleteItem>
                      ))}
                  </AutoCompleteList>
                </AutoComplete>
                <InputRightElement
                  pointerEvents="none"
                  // eslint-disable-next-line react/no-children-prop
                  children={<BiSearch color="gray.300" />}
                />
              </InputGroup>
            </>
          )}
          <InputGroup
            zIndex={2}
            display="flex"
            w="100%"
            mt="15px"
            alignContent="space-between"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box display="flex" flexDir="row" alignContent="center" alignItems="baseline" justifyContent="start">
              <Text fontSize="14px" fontWeight="bold" textAlign="start" mb="10px" mr="10px">
                {translates(language)?.searchbox?.default?.item4}
              </Text>
              <FaArrowRight color="black" style={{ paddingTop: 3 }} size={14} />
              <Text fontSize="14px" fontWeight="bold" textAlign="start" mb="10px" ml="10px">
                {translates(language)?.searchbox?.default?.item5}
              </Text>
            </Box>

            <RangeDatepicker
              onChange={async () => {
                if (destinationId) {
                  await getSearchHotels({
                    id: destinationId,
                    hotelID: hotelID != 0 ? hotelID : null,
                    minimunPrice: mnPrice,
                    maxPrice: mxPrice,
                    numNights: nNights,
                    roomsOnly: roomOnly,
                    sRooms: sR,
                    nation: nationality,
                  });
                  await setCategorties(old => [...old]);
                } else {
                  setVerifySrc(translates(language)?.filters?.item23);
                }
              }}
              selectedDates={selectedDates}
              onDateChange={setSelectedDates}
            />
          </InputGroup>
          {nNights <= 0 && (
            <Box mt="10px" align="start">
              <Text fontSize="10px" color="red">
                Data inválida para reserva
              </Text>
            </Box>
          )}
          <Box zIndex={4} mt="15px" display="flex" w="100%" alignContent="flex-start" flexDirection="column">
            <Text fontSize="14px" fontWeight="bold" textAlign="start" mb="10px">
              {translates(language)?.searchbox?.default?.item6}
            </Text>
            <CounterBox zIndex={4} rooms={rooms} setRooms={setRooms} />
          </Box>
          <Box mt="15px" display="flex" w="100%" alignContent="flex-start" flexDirection="column">
            <Button onClick={() => setAdvFilters(!advFilters)}>
              {advFilters ? translates(language)?.filters?.item2 : translates(language)?.filters?.item1}
            </Button>
          </Box>
          {advFilters && (
            <Box
              mt="30px"
              mb="30px"
              bg="theme.white"
              w="100%"
              borderRadius="8px"
              borderWidth={1}
              p="20px"
              borderColor="gray.300"
              minH="400px"
            >
              {hotels?.hotels && (
                <>
                  <Text fontSize="14px" fontWeight="bold" textAlign="start" mb="10px">
                    {translates(language)?.filters?.item3}
                  </Text>
                  <InputGroup zIndex={1}>
                    <AutoComplete
                      emptyState={loadingD ? '' : 'Nenhum resultado encontrado'}
                      openOnFocus
                      onSuspend={false}
                    >
                      <AutoCompleteInput
                        onChange={handleChangeHotel}
                        value={searchTerm}
                        borderColor={verifySrc != '' ? 'red' : 'gray.200'}
                        placeholder="Busque hotel"
                      />
                      <AutoCompleteList>
                        {loadingD
                          ? translates(language)?.searchbox?.default?.item13
                          : hotels &&
                            hotels?.hotels?.Hotels?.map((h, idx) => (
                              <AutoCompleteItem
                                alignItems="center"
                                onClick={() => {
                                  if (destinationId) {
                                    setSearchTerm(h?.Name);
                                  } else {
                                    setVerifySrcH(h?.Name);
                                    setSearchTerm(h?.Name);
                                  }
                                }}
                                key={`option-${idx}`}
                                value={`${h?.Name}`}
                                textTransform="capitalize"
                              >
                                {`${h?.Name}`}
                              </AutoCompleteItem>
                            ))}
                      </AutoCompleteList>
                    </AutoComplete>
                    <InputRightElement
                      pointerEvents="none"
                      // eslint-disable-next-line react/no-children-prop
                      children={<BiSearch color="gray.300" />}
                    />
                  </InputGroup>
                </>
              )}
              <Text mt="20px" fontSize="14px" fontWeight="bold" textAlign="start" mb="10px">
                Reais (BRL)
              </Text>
              <Box height={50}>
                <RangeSlider
                  aria-label={['min', 'max']}
                  onClickCapture={async () => {
                    if (destinationId) {
                    } else {
                      setVerifySrc(translates(language)?.filters?.item23);
                    }
                  }}
                  onChange={async e => {
                    await setFilterPrice(filterPrice !== 'asc' ? 'desc' : 'asc');
                    await setFilterPrice(filterPrice !== 'desc' ? 'asc' : 'desc');
                    await setMnPrice(e[0]);
                    await setMxPrice(e[1]);
                    await handleUpdate(e);
                    setChangePrices(true);
                  }}
                  value={[mnPrice, mxPrice]}
                  min={initialMnPrice || 10}
                  max={initialMxPrice || 999999}
                >
                  <RangeSliderTrack bg="black">
                    <RangeSliderFilledTrack bg="black" />
                  </RangeSliderTrack>

                  <RangeSliderThumb zIndex={0.5} boxSize={4} bg="black" index={0} />
                  <RangeSliderThumb zIndex={0.5} boxSize={4} bg="black" index={1} />
                </RangeSlider>
              </Box>
              <InputGroup display="flex" w="100%" justifyContent="space-between" flexDirection="row">
                <Input
                  ref={minRef}
                  type="number"
                  defaultValue={initialMnPrice}
                  onChange={async e => {
                    await setFilterPrice(filterPrice !== 'asc' ? 'desc' : 'asc');
                    await setFilterPrice(filterPrice !== 'desc' ? 'asc' : 'desc');
                    await setMnPrice(Number(e?.target?.value));
                    setChangePrices(true);
                  }}
                  w="40%"
                  placeholder="Min."
                />
                <Text fontSize="24px" fontWeight="bold" verticalAlign="center" textAlign="center">
                  -
                </Text>
                <Input
                  ref={maxRef}
                  type="number"
                  w="40%"
                  defaultValue={initialMxPrice}
                  onChange={async e => {
                    await setFilterPrice(filterPrice !== 'asc' ? 'desc' : 'asc');
                    await setFilterPrice(filterPrice !== 'desc' ? 'asc' : 'desc');
                    await setMxPrice(Number(e?.target?.value));
                    setChangePrices(true);
                  }}
                  placeholder="Máx."
                />
              </InputGroup>
              {changePrices && (
                <Button
                  mt="30px"
                  alignSelf="end"
                  isLoading={loading}
                  disabled={nNights <= 0}
                  w="100%"
                  variant="outline"
                  colorScheme="brand"
                  onClick={() => handleApplyFilters()}
                >
                  {translates(language)?.filters?.item25}
                </Button>
              )}
              <Accordion mt="20px" defaultIndex={[0]} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {translates(language)?.filters.item21}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel align="left" pb={4}>
                    <Box display="flex" flexDir="column">
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(5)}>
                        <Box display="flex" flexDir="row">
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(4.5)}>
                        <Box display="flex" flexDir="row">
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarHalf color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(4)}>
                        <Box display="flex" flexDir="row">
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(3.5)}>
                        <Box display="flex" flexDir="row">
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarHalf color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(3)}>
                        <Box display="flex" flexDir="row">
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(2.5)}>
                        <Box display="flex" flexDir="row">
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                          <BsStarHalf color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(2)}>
                        <Box display="flex" flexDir="row">
                          <BsStarFill color="#EDCA4D" />
                          <BsStarFill color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(1.5)}>
                        <Box display="flex" flexDir="row">
                          <BsStarFill color="#EDCA4D" />
                          <BsStarHalf color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(1)}>
                        <Box display="flex" flexDir="row">
                          <BsStarFill color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                      <Checkbox mb="10px" colorScheme="red" defaultChecked onChange={() => delOrAddCategorie(0.5)}>
                        <Box display="flex" flexDir="row">
                          <BsStarHalf color="#EDCA4D" />
                        </Box>
                      </Checkbox>
                    </Box>
                    {changeCategories && (
                      <Button
                        mt="30px"
                        alignSelf="end"
                        w="100%"
                        variant="outline"
                        colorScheme="brand"
                        onClick={() => applyCategoryFilters()} // Função que aplica os filtros de categorias
                      >
                        {translates(language)?.filters?.item25 || 'Aplicar'}
                      </Button>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <Accordion mt="20px" defaultIndex={[0]} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Café da manhã
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel align="left" pb={4}>
                    <Box display="flex" flexDirection="column">
                      <Checkbox
                        isChecked={filterHasBreakfast === true}
                        onChange={() =>
                          toggleCheckbox(filterHasBreakfast, setFilterHasBreakfast, setShowApplyBreakfast)
                        }
                        mt="5px"
                        sx={checkboxStyles}
                      >
                        Com café da manhã
                      </Checkbox>
                      <Checkbox
                        isChecked={filterHasBreakfast === false}
                        onChange={() =>
                          toggleCheckbox(filterHasBreakfast, setFilterHasBreakfast, setShowApplyBreakfast)
                        }
                        mt="5px"
                        sx={checkboxStyles}
                      >
                        Sem café da manhã
                      </Checkbox>
                      <Checkbox
                        isChecked={filterHasBreakfast === null}
                        onChange={() =>
                          toggleCheckbox(filterHasBreakfast, setFilterHasBreakfast, setShowApplyBreakfast)
                        }
                        mt="5px"
                        sx={checkboxStyles}
                      >
                        Todos
                      </Checkbox>
                    </Box>
                    {showApplyBreakfast && (
                      <Button
                        mt="10px"
                        bg="#000000"
                        color="#FFFFFF"
                        width="100%"
                        onClick={() => {
                          handleApplyFilters();
                          setShowApplyBreakfast(false);
                        }}
                      >
                        Aplicar
                      </Button>
                    )}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Tudo incluso
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel align="left" pb={4}>
                    <Box display="flex" flexDirection="column">
                      <Checkbox
                        isChecked={filterAllInclusive === true}
                        onChange={() =>
                          toggleCheckbox(filterAllInclusive, setFilterAllInclusive, setShowApplyAllInclusive)
                        }
                        mt="5px"
                        sx={checkboxStyles}
                      >
                        Tudo incluso
                      </Checkbox>
                      <Checkbox
                        isChecked={filterAllInclusive === false}
                        onChange={() =>
                          toggleCheckbox(filterAllInclusive, setFilterAllInclusive, setShowApplyAllInclusive)
                        }
                        mt="5px"
                        sx={checkboxStyles}
                      >
                        Sem tudo incluso
                      </Checkbox>
                      <Checkbox
                        isChecked={filterAllInclusive === null}
                        onChange={() =>
                          toggleCheckbox(filterAllInclusive, setFilterAllInclusive, setShowApplyAllInclusive)
                        }
                        mt="5px"
                        sx={checkboxStyles}
                      >
                        Todos
                      </Checkbox>
                    </Box>
                    {showApplyAllInclusive && (
                      <Button
                        mt="10px"
                        bg="#000000"
                        color="#FFFFFF"
                        width="100%"
                        onClick={() => {
                          handleApplyFilters();
                          setShowApplyAllInclusive(false);
                        }}
                      >
                        Aplicar
                      </Button>
                    )}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Política de Cancelamento
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel align="left" pb={4}>
                    <Box display="flex" flexDirection="column">
                      <Checkbox
                        isChecked={filterCancellationPolicy === true}
                        onChange={() =>
                          toggleCheckbox(
                            filterCancellationPolicy,
                            setFilterCancellationPolicy,
                            setShowApplyCancellation,
                          )
                        }
                        mt="5px"
                        sx={checkboxStyles}
                      >
                        Com política de cancelamento
                      </Checkbox>
                      <Checkbox
                        isChecked={filterCancellationPolicy === false}
                        onChange={() =>
                          toggleCheckbox(
                            filterCancellationPolicy,
                            setFilterCancellationPolicy,
                            setShowApplyCancellation,
                          )
                        }
                        mt="5px"
                        sx={checkboxStyles}
                      >
                        Sem política de cancelamento
                      </Checkbox>
                      <Checkbox
                        isChecked={filterCancellationPolicy === null}
                        onChange={() =>
                          toggleCheckbox(
                            filterCancellationPolicy,
                            setFilterCancellationPolicy,
                            setShowApplyCancellation,
                          )
                        }
                        mt="5px"
                        sx={checkboxStyles}
                      >
                        Todos
                      </Checkbox>
                    </Box>
                    {showApplyCancellation && (
                      <Button
                        mt="10px"
                        bg="#000000"
                        color="#FFFFFF"
                        width="100%"
                        onClick={() => {
                          handleApplyFilters();
                          setShowApplyCancellation(false);
                        }}
                      >
                        Aplicar
                      </Button>
                    )}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Pensão
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text mb="10px">Pensão Completa</Text>
                    <Checkbox
                      isChecked={filterFullBoard === true}
                      onChange={() => toggleCheckbox(filterFullBoard, setFilterFullBoard, setShowApplyFullBoard)}
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Sim
                    </Checkbox>
                    <Checkbox
                      isChecked={filterFullBoard === false}
                      onChange={() => toggleCheckbox(filterFullBoard, setFilterFullBoard, setShowApplyFullBoard)}
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Não
                    </Checkbox>
                    <Checkbox
                      isChecked={filterFullBoard === null}
                      onChange={() => toggleCheckbox(filterFullBoard, setFilterFullBoard, setShowApplyFullBoard)}
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Todos
                    </Checkbox>

                    <Text mt="20px" mb="10px">
                      Meia Pensão
                    </Text>
                    <Checkbox
                      isChecked={filterHalfBoard === true}
                      onChange={() => toggleCheckbox(filterHalfBoard, setFilterHalfBoard, setShowApplyHalfBoard)}
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Sim
                    </Checkbox>
                    <Checkbox
                      isChecked={filterHalfBoard === false}
                      onChange={() => toggleCheckbox(filterHalfBoard, setFilterHalfBoard, setShowApplyHalfBoard)}
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Não
                    </Checkbox>
                    <Checkbox
                      isChecked={filterHalfBoard === null}
                      onChange={() => toggleCheckbox(filterHalfBoard, setFilterHalfBoard, setShowApplyHalfBoard)}
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Todos
                    </Checkbox>

                    {(showApplyFullBoard || showApplyHalfBoard) && (
                      <Button
                        mt="20px"
                        bg="#000000"
                        color="#FFFFFF"
                        width="100%"
                        onClick={() => {
                          handleApplyFilters();
                          setShowApplyFullBoard(false);
                          setShowApplyHalfBoard(false);
                        }}
                      >
                        Aplicar
                      </Button>
                    )}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Ofertas Especiais
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text mb="10px">Ofertas Especiais</Text>
                    <Checkbox
                      isChecked={filterSpecialOffer === true}
                      onChange={() =>
                        toggleCheckbox(filterSpecialOffer, setFilterSpecialOffer, setShowApplySpecialOffer)
                      }
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Sim
                    </Checkbox>
                    <Checkbox
                      isChecked={filterSpecialOffer === false}
                      onChange={() =>
                        toggleCheckbox(filterSpecialOffer, setFilterSpecialOffer, setShowApplySpecialOffer)
                      }
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Não
                    </Checkbox>
                    <Checkbox
                      isChecked={filterSpecialOffer === null}
                      onChange={() =>
                        toggleCheckbox(filterSpecialOffer, setFilterSpecialOffer, setShowApplySpecialOffer)
                      }
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Todos
                    </Checkbox>
                    {showApplySpecialOffer && (
                      <Button
                        mt="20px"
                        bg="#000000"
                        color="#FFFFFF"
                        width="100%"
                        onClick={() => {
                          handleApplyFilters();
                          setShowApplySpecialOffer(false);
                        }}
                      >
                        Aplicar
                      </Button>
                    )}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Top Hotel
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Checkbox
                      isChecked={filterTopHotel === true}
                      onChange={() => toggleCheckbox(filterTopHotel, setFilterTopHotel, setShowApplyTopHotel)}
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Sim
                    </Checkbox>
                    <Checkbox
                      isChecked={filterTopHotel === false}
                      onChange={() => toggleCheckbox(filterTopHotel, setFilterTopHotel, setShowApplyTopHotel)}
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Não
                    </Checkbox>
                    <Checkbox
                      isChecked={filterTopHotel === null}
                      onChange={() => toggleCheckbox(filterTopHotel, setFilterTopHotel, setShowApplyTopHotel)}
                      ml="5px"
                      mt="5px"
                      mr="5px"
                      sx={checkboxStyles}
                    >
                      Todos
                    </Checkbox>
                    {showApplyTopHotel && (
                      <Button
                        mt="20px"
                        bg="#000000"
                        color="#FFFFFF"
                        width="100%"
                        onClick={() => {
                          handleApplyFilters();
                          setShowApplyTopHotel(false);
                        }}
                      >
                        Aplicar
                      </Button>
                    )}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Serviços Disponíveis
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {selectedServices.length > 0 ? (
                      selectedServices.map((service, index) => (
                        <Checkbox
                          key={index}
                          isChecked={selectedServices.includes(service)}
                          onChange={e => {
                            const newSelectedServices = selectedServices.includes(service)
                              ? selectedServices.filter(s => s !== service)
                              : [...selectedServices, service];
                            setSelectedServices(newSelectedServices);
                          }}
                          ml="5px"
                          mt="5px"
                          mr="5px"
                          sx={checkboxStyles}
                        >
                          {service}
                        </Checkbox>
                      ))
                    ) : (
                      <Text fontStyle="italic" fontSize="sm" color="gray.500">
                        Nenhum serviço disponível
                      </Text>
                    )}
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Personalizados
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {selectedCustomFields.length > 0 ? (
                      selectedCustomFields.map((field, index) => (
                        <Checkbox
                          key={index}
                          isChecked={selectedCustomFields.includes(field)}
                          onChange={e => {
                            const newSelectedCustomFields = selectedCustomFields.includes(field)
                              ? selectedCustomFields.filter(f => f !== field)
                              : [...selectedCustomFields, field];
                            setSelectedCustomFields(newSelectedCustomFields);
                          }}
                          ml="5px"
                          mt="5px"
                          mr="5px"
                          sx={checkboxStyles}
                        >
                          {field}
                        </Checkbox>
                      ))
                    ) : (
                      <Text fontStyle="italic" fontSize="sm" color="gray.500">
                        Nenhum campo personalizado disponível
                      </Text>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Button mt="30px" w="100%" variant="outline" bg="#000000" color="#FFFFFF" onClick={handleApplyFilters}>
                Aplicar
              </Button>
            </Box>
          )}

          <Button
            mt="30px"
            alignSelf="end"
            isLoading={loading}
            disabled={nNights <= 0}
            w="100%"
            variant="outline"
            colorScheme="brand"
            onClick={async () => {
              if (destinationId) {
                await getSearchHotels({
                  id: destinationId,
                  hotelID: hotelID != 0 ? hotelID : null,
                  minimunPrice: mnPrice,
                  maxPrice: mxPrice,
                  numNights: nNights,
                  roomsOnly: roomOnly,
                  sRooms: sR,
                  nation: nationality,
                });
                await setCategorties(old => [...old]);
                await applyCategoryFilters();
                window.scrollTo(0, 0);
              } else {
                setVerifySrc(translates(language)?.filters?.item23);
              }
            }}
          >
            {translates(language)?.filters?.item22}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const checkboxStyles = {
  '.chakra-checkbox__control': {
    borderColor: '#000000',
  },
  '.chakra-checkbox__control[data-checked]': {
    backgroundColor: '#000000',
    borderColor: '#000000',
  },
  '.chakra-checkbox__icon': {
    color: '#FFFFFF',
  },
};
