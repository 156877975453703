import React, { useState } from "react";
import { Box, Input, Popover, PopoverTrigger, PopoverContent, PopoverBody, Button } from "@chakra-ui/react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";

export const CustomSingleDatepicker = ({ selectedDate, onDateChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(selectedDate || new Date());

  const toggleCalendar = () => setIsOpen(!isOpen);
  const handleDateChange = (newDate) => {
    setDate(newDate);
    onDateChange(newDate);
    toggleCalendar(); // Fechar o calendário após selecionar a data
  };

  return (
    <Popover isOpen={isOpen} onClose={toggleCalendar}>
      <PopoverTrigger>
      <Button
        fontWeight="thin"
        backgroundColor="white"
        border="1px solid #E2E8F0"
        onClick={toggleCalendar}
        _hover={{ backgroundColor: 'white' }}
      >
          {dayjs(date).format("DD/MM/YYYY")}
        </Button>
      </PopoverTrigger>
      <PopoverContent width="auto">
        <PopoverBody>
          <Calendar value={date} onChange={handleDateChange} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}