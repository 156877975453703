/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
  Box,
  Divider,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import { FaBaby, FaBed, FaChild, FaUser, FaUsers } from 'react-icons/fa'
import Adult from './adult'
import Children from './children'
import { calcHops } from '../../utils/hotelsSearch'
import { translates } from '../../utils/languague'
import { useLanguage } from '../../providers/LanguageProvider'
import Baby from './baby'

export default function CounterBoxVoos({
  adultQuant,
  setAdultQuant,
  childQuant,
  setChildQuant,
  babyQuant,
  setBabyQuant,
}) {
  const { language } = useLanguage()
  const [isOpen, setIsOpen] = React.useState(false)
  const open = () => setIsOpen(!isOpen)
  const close = () => setIsOpen(false)
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={close}
      onOpen={open}
      placement="bottom"
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Button>
          <FaUser size={20} style={{ marginLeft: 8, marginRight: 8 }} />
          <Box display={{base: 'none', md: 'block'}}>{`${translates(language)?.searchBoxVoos?.adults}`}</Box>
          {`: ${adultQuant}`}

          <FaChild size={20} style={{ marginLeft: 8, marginRight: 8 }} />
          <Box display={{base: 'none', md: 'block'}}>{`${translates(language)?.searchBoxVoos?.childs}`}</Box>
          {`: ${childQuant}`}

          <FaBaby size={20} style={{ marginLeft: 8, marginRight: 8 }} />
          <Box display={{base: 'none', md: 'block'}}>{`${translates(language)?.searchBoxVoos?.babies}`}</Box>
          {`: ${babyQuant}`}
        </Button>
      </PopoverTrigger>
      <Portal>
        <Box zIndex="sticky">
          <PopoverContent width="400px">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Box margin="10px" display="flex" flexDirection="column" alignItems="center">
                <span>
                  <Box display="flex" flexDirection="row">
                    <Adult quantAdult={adultQuant} setQuantAdult={setAdultQuant} />
                    <Children childQuant={childQuant} setChildQuant={setChildQuant} />
                    <Baby babyQuant={babyQuant} setBabyQuant={setBabyQuant} />
                  </Box>
                </span>
              </Box>
            </PopoverBody>
            <Button m="10px" colorScheme="red" onClick={close}>
              Confirmar
            </Button>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  )
}
