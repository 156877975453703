/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  InputGroup,
  InputRightElement,
  Text,
  Container,
  FormLabel,
  Stack,
  HStack,
  VStack,
  SimpleGrid,
  Input,
  Radio,
  Select,
  RadioGroup,
  Divider,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  ButtonGroup,
} from '@chakra-ui/react';
import { BiSearch } from 'react-icons/bi';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from '@choc-ui/chakra-autocomplete';
import { RangeDatepicker } from '../RangeDatePicker';
import CounterBox from '../CounterBox';
import { calcDate, selectTypeDstn, typeDestination } from '../../utils/hotelsSearch'; //typeDestination é a propriedade que chama o tipo de ícone
import { useSearch } from '../../providers/SearchProvider';
import { nationalitys } from '../../utils/nationalitys';
import { useLanguage } from '../../providers/LanguageProvider';
import { translates } from '../../utils/languague';
import DentinationSearch from '../DestinationSearch';
import CounterBoxVoos from '../CounterBoxVoos';
import moment from 'moment';
import { useSearchDestinationVoosProvider } from '../../providers/SearchDestinationVoosProvider';
import DestinationSearchAirport from '../DestinationSearchAirport';
import { IFrameVoos } from '../IFrameVoos';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { ptBR } from "date-fns/locale";
import { CustomDatePicker } from '../CustomDatePicker';
import { CustomSingleDatepicker } from '../CustomSingleDatePicker';

export default function SearchBox() {
  const navigate = useNavigate();
  const {
    nationality,
    setNationality,
    loadingN,
    mnPrice,
    mxPrice,
    nNights,
    setNNights,
    roomOnly,
    sR,
    setSR,
    getSearchHotels,
    destinations,
    destinationId,
    setSearch,
    selectedDates,
    setSelectedDates,
    rooms,
    setRooms,
    loading,
    loadingD,
    onChanged,
    setDestinationID,
    hotelID,
    setHotelID,
  } = useSearch();

  const {
    origin,
    onChangeOrigin,
    isLoadingOrigin,
    setIDDestinationOrigin,
    setSearchOrigin,
    idDestinationOrigin,
    onChangeDestiny,
    destiny,
    isLoadingDestiny,
    setIDDestinationDestiny,
    setSearchDestiny,
    idDestinationDestiny,
    urlIFrame,
    setUrlIFrame,
    dateOnlyGoing,
    setDateOnlyGoing
  } = useSearchDestinationVoosProvider();

  const { language } = useLanguage();

  const [verifySrc, setVerifySrc] = useState('');
  const [verifySrcOrigin, setVerifySrcOrigin] = useState('');
  const [verifySrcDestiny, setVerifySrcDestiny] = useState('');
  const [isStranger, setIsStranger] = useState(false);
  const [adultQuant, setAdultQuant] = useState(1);
  const [childQuant, setChildQuant] = useState(0);
  const [babyQuant, setBabyQuant] = useState(0);
  const [selectedDatesVoosUrl, setSelectedDatesVoosUrl] = useState([new Date(), new Date()]);
  const [selectedDatesVoos, setSelectedDatesVoos] = useState([new Date(), new Date()]);
  const [selectedRadio, setSelectedRadio] = useState('2');
  const [customTabIndex, setCustomTabIndex] = useState(0);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    setNNights(calcDate(selectedDates));
  }, [selectedDates]);

  useEffect(() => {
    setSelectedDatesVoosUrl(selectedDatesVoos);
  }, [selectedDatesVoos]);

  useEffect(() => {
    setSR(rooms);
  }, [rooms]);

  const mapping = [0];
  const inputReference = useRef(null);
  const inputReferenceOrigin = useRef(null);
  const inputReferenceDestiny = useRef(null);

  const handleSetVoos = () => {
    setCustomTabIndex(1);
    scrollContainerRef.current.scrollTo({
      left: scrollContainerRef.current.scrollWidth / 2, // Rola até a metade (segunda div)
    });
  };

  const blockScroll = (e) => {
    e.preventDefault(); // Previne o scroll padrão
  };

  const pattern = (
    <>
      <ButtonGroup>
        <Button
          border={customTabIndex === 0 ? '2px solid grey' : 'none'}
          onClick={() => {
            setCustomTabIndex(0)
          }}
        >
          {translates(language)?.searchbox?.tab}
        </Button>

        <Button
          border={customTabIndex === 1 ? '2px solid grey' : 'none'}
          onClick={() => {
            setCustomTabIndex(1)
          }}
        >
          {translates(language)?.searchBoxVoos?.tab}
        </Button>
      </ButtonGroup>
      <div 
      >
        {customTabIndex === 0 && (
          <SimpleGrid columns={[1, 1, 1, 3]} spacing={4} marginTop={4}>
          <Stack spacing={4}>
            <VStack alignItems={'flex-start'}>
              <Text fontSize="16px" fontWeight="medium">
                {translates(language)?.searchbox?.default?.item1}
              </Text>
              <DentinationSearch
                inputReference={inputReference}
                verifySrc={verifySrc}
                onChanged={onChanged}
                destinations={destinations}
                loadingD={loadingD}
                mapping={mapping}
                setDestinationID={setDestinationID}
                setHotelID={setHotelID}
                setSearch={setSearch}
                setVerifySrc={setVerifySrc}
              />
            </VStack>

            <VStack alignItems={'flex-start'}>
              <Checkbox onChange={() => setIsStranger(!isStranger)} colorScheme="red">
                {translates(language)?.searchbox?.default?.item3}
              </Checkbox>

              {isStranger && (
                <VStack alignItems="flex-start">
                  <Text fontSize="16px" fontWeight="medium">
                    Nacionalidade
                  </Text>
                  <InputGroup>
                    <AutoComplete
                      emptyState={loadingD ? '' : 'Nenhum resultado encontrado'}
                      openOnFocus
                      onSuspend={false}
                    >
                      <AutoCompleteInput
                        borderColor={verifySrc != '' && 'red'}
                        placeholder="Busque sua nacionalidade"
                      />
                      <AutoCompleteList>
                        {loadingN
                          ? translates(language)?.searchbox?.default?.item13
                          : nationalitys &&
                            nationalitys?.map((nt, idx) => (
                              <AutoCompleteItem
                                alignItems="center"
                                onClick={() => setNationality(nt?.iso2Code)}
                                key={`option1-${idx}`}
                                value={`${nt?.name}`}
                                textTransform="capitalize"
                              >
                                {`${nt?.name}`}
                              </AutoCompleteItem>
                            ))}
                      </AutoCompleteList>
                    </AutoComplete>
                    <InputRightElement
                      pointerEvents="none"
                      // eslint-disable-next-line react/no-children-prop
                      children={<BiSearch color="gray.300" />}
                    />
                  </InputGroup>
                </VStack>
              )}
            </VStack>
          </Stack>

          <InputGroup display={'flex'} flexDirection={'column'}>
            <VStack alignItems="flex-start">
              <HStack justifyContent={'flex-start'} alignItems={'center'}>
                <Text fontSize="16px" fontWeight="medium">
                  {translates(language)?.searchbox?.default?.item4}
                </Text>
                <FaArrowRight color="black" size={14} />
                <Text fontSize="16px" fontWeight="medium">
                  {translates(language)?.searchbox?.default?.item5}
                </Text>
              </HStack>

              <RangeDatepicker selectedDates={selectedDates} onDateChange={setSelectedDates} />
              {nNights <= 0 && (
                <Box mt="10px" align="start">
                  <Text fontSize="14px" color="red">
                    Data inválida para reserva
                  </Text>
                </Box>
              )}
            </VStack>
          </InputGroup>
          <Stack>
            <Text fontSize="16px" fontWeight="medium">
              {translates(language)?.searchbox?.default?.item6}
            </Text>
            <Box display="flex" flexDir={['column', 'row']}>
              <CounterBox rooms={rooms} setRooms={setRooms} />
              <Button
                zIndex={0}
                alignSelf={['start', 'end']}
                ml={['0px', '10px']}
                mt={['16px', '0px']}
                disabled={nNights <= 0}
                isLoading={loading}
                variant="outline"
                colorScheme="brand"
                onClick={async () => {
                  if (destinationId) {
                    await navigate('search');
                    await getSearchHotels({
                      id: destinationId,
                      hotelID: hotelID != 0 ? hotelID : null,
                      minimunPrice: mnPrice,
                      maxPrice: mxPrice,
                      numNights: nNights,
                      roomsOnly: roomOnly,
                      sRooms: sR,
                      nation: nationality,
                      firstSearch: true,
                    });
                    await localStorage.setItem('timer', true);
                  } else {
                    setVerifySrc(translates(language)?.filters.item23);
                  }
                }}
              >
                {translates(language)?.searchbox?.default?.item12}
              </Button>
            </Box>
          </Stack>

          {/* {verifySrc != '' && (
            <Box mt="10px" align="start">
              <Text color="red">{verifySrc}</Text>
            </Box>
          )} */}
        </SimpleGrid>
        )}


        {customTabIndex === 1 && (
          <SimpleGrid columns={[1, 1, 1, 3]} spacing={4} marginTop={4} paddingBottom={50}>
              <VStack alignItems={'flex-start'}>
                <Text fontSize="16px" fontWeight="medium">
                  {translates(language)?.searchBoxVoos?.origin}
                </Text>
                <DestinationSearchAirport
                  inputReference={inputReferenceOrigin}
                  verifySrc={verifySrcOrigin}
                  onChanged={onChangeOrigin}
                  destinations={origin}
                  loadingD={isLoadingOrigin}
                  setDestinationID={setIDDestinationOrigin}
                  setSearch={setSearchOrigin}
                />
              </VStack>

              <VStack alignItems={'flex-start'}>
                <Text fontSize="16px" fontWeight="medium">
                  {translates(language)?.searchBoxVoos?.destiny}
                </Text>
                <DestinationSearchAirport
                  inputReference={inputReferenceDestiny}
                  verifySrc={verifySrcDestiny}
                  onChanged={onChangeDestiny}
                  destinations={destiny}
                  loadingD={isLoadingDestiny}
                  setDestinationID={setIDDestinationDestiny}
                  setSearch={setSearchDestiny}
                />
              </VStack>

              <VStack alignItems={'flex-start'}>
                <HStack justifyContent={'flex-start'} alignItems={'center'}>
                  <Text fontSize="16px" fontWeight="medium">
                    {translates(language)?.searchBoxVoos?.departureDate}
                  </Text>
                  <FaArrowRight color="black" size={14} />
                  <Text fontSize="16px" fontWeight="medium">
                    {translates(language)?.searchBoxVoos?.returnDate}
                  </Text>
                </HStack>

                { selectedRadio === '1' ? (

                  <CustomSingleDatepicker
                    selectedDate={dateOnlyGoing}
                    onDateChange={(newDate) => setDateOnlyGoing(newDate)}
                  />
                ) : (
                  <RangeDatepicker selectedDates={selectedDatesVoos} onDateChange={setSelectedDatesVoos} />
                )}

                {/* <CustomDatePicker date={date} setDate={setDate} selectedDates={selectedDatesVoos} onDateChange={setSelectedDatesVoos} /> */}

              </VStack>

              <VStack alignItems={'flex-start'}>
                <Text fontSize="16px" fontWeight="medium">
                  {translates(language)?.searchBoxVoos?.selectOneOptions}
                </Text>
                <RadioGroup onChange={setSelectedRadio} value={selectedRadio}>
                  <HStack alignItems={'flex-start'}>
                    <Radio value={'2'}>{translates(language)?.searchBoxVoos?.goingAndReturn}</Radio>
                    <Radio value={'1'}>{translates(language)?.searchBoxVoos?.onlyGoing}</Radio>
                  </HStack>
                </RadioGroup>
              </VStack>

              <VStack alignItems={'flex-start'}>
                <Text fontSize="16px" fontWeight="medium">
                  {translates(language)?.searchBoxVoos?.quantAdultChildBaby}
                </Text>
                <Box display="flex" flexDir={['column', 'row']}>
                  <CounterBoxVoos
                    adultQuant={adultQuant}
                    setAdultQuant={setAdultQuant}
                    childQuant={childQuant}
                    setChildQuant={setChildQuant}
                    babyQuant={babyQuant}
                    setBabyQuant={setBabyQuant}
                  />
                </Box>
              </VStack>

              <VStack alignItems={'flex-start'}>
                <Box
                  width="100%"
                  height="100%"
                  justifyContent={'flex-end'}
                  alignItems={'flex-end'}
                  display="flex"
                  flexDir={['column', 'row']}
                >
                  <Button
                    zIndex={0}
                    alignSelf={['start', 'end']}
                    ml={['0px', '10px']}
                    mt={['16px', '0px']}
                    disabled={nNights <= 0}
                    isLoading={loading}
                    variant="outline"
                    colorScheme="brand"
                    onClick={() => {
                      if (idDestinationOrigin.length > 0 && idDestinationDestiny.length > 0) {
                        setVerifySrcDestiny('');
                        setVerifySrcOrigin('');
                        const url = process.env.REACT_APP_VOOS.replace('adultos=', `adultos=${adultQuant}`)
                          .replace('criancas=', `criancas=${childQuant}`)
                          .replace('bebes=', `bebes=${babyQuant}`)
                          .replace('origem=', `origem=${idDestinationOrigin}`)
                          .replace('destino=', `destino=${idDestinationDestiny}`)
                          .replace('tipo=', `tipo=${selectedRadio}`)
                          .replace('dataIda=', `dataIda=${selectedRadio === '2' ? moment(selectedDatesVoosUrl[0]).format('YYYY-MM-DD') : moment(dateOnlyGoing).format('YYYY-MM-DD')}`)
                          .replace(
                            'dataVolta=',
                            `dataVolta=${
                              selectedRadio === '2' ? moment(selectedDatesVoosUrl[1]).format('YYYY-MM-DD') : ''
                            }`,
                          );
                        setUrlIFrame(url);

                        console.log('url ==============================================');
                        console.log(url);

                        //window.open(url, '_blank')
                        // const popUp = window.open(
                        //   url,
                        //   '_blank',
                        //   'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600',
                        // )

                        // if (!popUp || popUp.closed || typeof popUp.closed === 'undefined') {
                        //   alert('Por favor, desbloqueie pop-ups para esta página.')
                        // }
                      } else if (idDestinationOrigin.length === 0) {
                        setVerifySrcOrigin('Selecione uma origem');
                        setVerifySrcDestiny('');
                      } else if (idDestinationDestiny.length === 0) {
                        setVerifySrcDestiny('Selecione um destino');
                        setVerifySrcOrigin('');
                      }
                    }}
                  >
                    {translates(language)?.searchbox?.default?.item12}
                  </Button>
                </Box>
              </VStack>
          </SimpleGrid>
        )}
      </div>

      {urlIFrame.length !== 0 && (
        <>
          <IFrameVoos url={urlIFrame} />
        </>
      )}
    </>
  );
  return (
    <Container maxW={1280}>
      <Box borderRadius={8} px={8} py={6} bg="white" w={'100%'}>
        {/* <Text
          color={'gray.700'}
          fontWeight={600}
          fontSize={'18px'}
          lineHeight={'125%'}
          letterSpacing={'-0.027em'}
          mb={1}
        >
          {translates(language)?.searchbox?.tab}
        </Text> */}
        {/* <Box maxW={'32px'} bg={'#272525'} colorScheme="red" height={'4px'}></Box> */}

        {pattern}
      </Box>
    </Container>
  );
}
