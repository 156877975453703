/* eslint-disable react/jsx-key */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */

import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import {
  Box,
  Button,
  Center,
  CircularProgress,
  Grid,
  GridItem,
  Input,
  InputGroup,
  List,
  ListIcon,
  ListItem,
  Progress,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { BiChevronLeft, BiChevronRight, BiHeart, BiMoon } from 'react-icons/bi';
import { MdCheckCircle } from 'react-icons/md';
import { CgCalendar, CgCalendarNext } from 'react-icons/cg';
import { BsStarFill } from 'react-icons/bs';
import { FaArrowRight, FaCalendarDay, FaUsers } from 'react-icons/fa';
import { rGrid2, rWidth } from '../../utils/responsiveSizes';
import BedRoomCard from '../BedRoomCard';
import { iconsAmn } from '../../utils/icons';
import { useSearch } from '../../providers/SearchProvider';
import { useLanguage } from '../../providers/LanguageProvider';
import { translates } from '../../utils/languague';
import moment from 'moment';
import 'moment/locale/pt-br';
import { calcHops } from '../../utils/hotelsSearch';
export default function SliderDetails({ images, loading, data }) {
  moment.locale('pt-br');
  const { hotelDetailsC, loadingC, selectedDates, nNights, rooms } = useSearch();

  const { language } = useLanguage();

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const carouselItemsRef = useRef([]);
  useEffect(() => {
    if (images && images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(0, images.length);

      setSelectedImageIndex(0);
      setSelectedImage(images[0]);
    }
  }, [images]);

  const handleSelectedImageChange = newIdx => {
    if (images && images.length > 0) {
      setSelectedImage(images[newIdx]);
      setSelectedImageIndex(newIdx);
    }
  };

  const handleRightClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= images.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (images && images.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = images.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  // eslint-disable-next-line consistent-return
  function column1(item, idx) {
    if (data?.amenities) {
      const divisor = Math.round(data?.amenities?.length / 3);
      if (idx === 0 || idx <= divisor) {
        return (
          <ListItem key={idx}>
            <ListIcon as={MdCheckCircle} />
            {item?.name[language] ?? ''}
          </ListItem>
        );
      }
    }
  }
  // eslint-disable-next-line consistent-return
  function column2(item, idx) {
    if (data?.amenities) {
      const divisor = Math.round(data?.amenities?.length / 3);
      if (idx > divisor && idx <= divisor * 2) {
        return (
          <ListItem key={idx}>
            <ListIcon as={MdCheckCircle} />
            {item?.name[language] ?? ''}
          </ListItem>
        );
      }
    }
  }
  // eslint-disable-next-line consistent-return
  function column3(item, idx) {
    if (data?.amenities) {
      const divisor = Math.round(data?.amenities?.length / 3);
      if (idx > divisor * 2) {
        return (
          <ListItem key={idx}>
            <ListIcon as={MdCheckCircle} />
            {item?.name[language] ?? ''}
          </ListItem>
        );
      }
    }
  }

  const regex = /(<([^>]+)>)/gi;

  const sortBedRooms = () => {
    if (
      Array.isArray(hotelDetailsC?.Hotels) &&
      hotelDetailsC?.Hotels.length > 0 &&
      Array.isArray(hotelDetailsC?.Hotels[0]?.Rooms)
    ) {
      return hotelDetailsC?.Hotels[0]?.Rooms?.sort((a, b) => a?.TotalSellingPrice?.Value - b?.TotalSellingPrice?.Value);
    }
    return [];
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {data.length != 0 && !loading && (
        <Box w={rWidth} mb="100px">
          {loading ? (
            'Carregando...'
          ) : (
            <>
              <Box w="100%" display="flex" flexDir={['column', 'row']}>
                <Box w={['100%', '70%']}>
                  <div className="carousel-container">
                    <div style={{ marginBottom: 20 }}>
                      <Box display="flex" flexDir={['row']}>
                        {Array.from({ length: data?.category }, () => (
                          <BsStarFill color="#EDCA4D" />
                        ))}
                      </Box>
                      <Text fontSize="35px" fontWeight="bold">
                        {data?.name}
                      </Text>
                      {hotelDetailsC?.Hotels?.length > 0 && (
                        <Text fontSize="18px">{hotelDetailsC?.Hotels?.[0]?.Address ?? ''}</Text>
                      )}
                      <Text fontSize="20px">
                        {`${data?.destination?.name[language]} - ${data.zipCode}`}
                        <a
                          style={{ textDecoration: 'underline', marginLeft: 10, fontSize: 17 }}
                          target="_blank"
                          href={`https://www.google.com/maps/search/?api=1&query=${data?.localization?.latitude},${data?.localization?.longitude}`}
                          rel="noreferrer"
                        >
                          Ver no mapa
                        </a>
                      </Text>
                      <Text fontWeight="bold" fontSize="16px">{`${moment(selectedDates[0]).format('LL')} até ${moment(
                        selectedDates[1],
                      ).format('LL')}`}</Text>
                    </div>
                    <Box display="flex" alignItems="center" flexDir="row" mb="10px" mt={['15px', -5]}>
                      <FaCalendarDay size={16} />
                      <Text ml="10px">
                        {nNights} {nNights == 1 ? 'Diária' : 'Diárias'}
                      </Text>
                      <Box mr="20px" />
                      <FaUsers size={20} />
                      <Text ml="10px">
                        {`${calcHops(rooms) ?? 0} ${translates(language)?.cards?.item2} ${
                          translates(language)?.cards?.item3
                        } ${rooms.length ?? 0} ${translates(language)?.cards?.item4}`}
                      </Text>
                    </Box>

                    <div className="selected-image" style={{ backgroundImage: `url(${selectedImage?.url})` }}>
                      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <button
                            className="carousel__button carousel__button-left"
                            onClick={handleLeftClick}
                            style={{ borderRadius: '50%', backgroundColor: 'white', position: 'relative' }}
                          >
                            <BiChevronLeft size={40} color="black" />
                          </button>
                        </div>
                        <div>
                          <button
                            className="carousel__button carousel__button-right"
                            onClick={handleRightClick}
                            style={{ borderRadius: '50%', backgroundColor: 'white', position: 'relative' }}
                          >
                            <BiChevronRight size={40} color="black" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="carousel">
                      <div className="carousel__images">
                        {images &&
                          images.map((image, idx) => (
                            <div
                              onClick={() => handleSelectedImageChange(idx)}
                              style={{ backgroundImage: `url(${image.url})` }}
                              key={image.id}
                              className={`carousel__image ${selectedImageIndex === idx && 'carousel__image-selected'}`}
                              ref={el => (carouselItemsRef.current[idx] = el)}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </Box>
                <Box w={['100%', '30%']}>
                  <Box
                    alignContent="center"
                    mt={[0, '15%']}
                    justifyContent="flex-end"
                    display="flex"
                    flexDir={['column', 'row']}
                  >
                    <Box visibility={['hidden', 'hidden']}>
                      <BiHeart display="none" size={50} />
                    </Box>
                  </Box>
                  <Box mt={[0, '20px']} borderWidth={2} borderRadius="8px" direction="row" h="80%" p={4}>
                    <Box borderBottomWidth={1} mt="20px" mb="20px" />
                    <Button
                      onClick={() =>
                        window.scrollTo({
                          top: 1500,
                          behavior: 'smooth',
                        })
                      }
                      w="100%"
                      colorScheme={'red'}
                    >
                      {translates(language)?.cards?.item8}
                    </Button>
                    {/* </Link> */}
                    <Box mt="50px">
                      <Text fontSize="20px" fontWeight="bold">
                        Amenidades
                      </Text>
                      <br />

                      <List spacing={2}>
                        {data?.amenities?.map(amentie => (
                          // eslint-disable-next-line react/jsx-key
                          <ListItem fontSize={20}>
                            <ListIcon mr="20px" as={iconsAmn(amentie.name[language])} />
                            {amentie?.name[language]}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Tabs isLazy colorScheme="red">
                  <TabList>
                    <Tab>Visão Geral</Tab>
                    <Tab>Amenidades</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <strong>Descrição</strong>
                      <br />
                      <br />
                      <p>{data?.description[language].replace(regex, '') ?? ''}</p>
                    </TabPanel>
                    <TabPanel>
                      <strong>Amenidades</strong>
                      <br />
                      <br />
                      {data?.amenities && (
                        <Box
                          w="100%"
                          justifyContent="space-between"
                          paddingEnd="100px"
                          display="flex"
                          flexDir={['column', 'row']}
                        >
                          <List spacing={3}>{data?.amenities?.map((item, idx) => column1(item, idx))}</List>
                          <List spacing={3}>{data?.amenities?.map((item, idx) => column2(item, idx))}</List>
                          <List spacing={3}>{data?.amenities?.map((item, idx) => column3(item, idx))}</List>
                        </Box>
                      )}
                    </TabPanel>
                    <TabPanel>
                      <strong>Bom saber</strong>
                      <br />
                      <br />
                      <Box display="flex" flexDir="column">
                        <Box>
                          <CgCalendarNext size={50} />
                          <Text>Entrada (Check-in): {data?.checkInBegin}</Text>
                          <Progress colorScheme="green" value={80} />
                        </Box>
                        <Box>
                          <CgCalendar size={50} />
                          <Text>Saìda (Check-out): {data?.checkOut}</Text>
                          <Progress colorScheme="green" value={80} />
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <strong>Disponibilidade</strong>
                      <br />
                      <br />
                      <Grid mt="30px" templateColumns={rGrid2} gap={6}>
                        <GridItem>
                          <InputGroup
                            display="flex"
                            w="100%"
                            alignContent="space-between"
                            justifyContent="space-between"
                            flexDirection="row"
                          >
                            <Box w="48%">
                              <Box
                                display="flex"
                                flexDir={['column', 'row']}
                                alignContent="center"
                                justifyContent="center"
                              >
                                <Text fontSize="14px" fontWeight="bold" textAlign="start" mb="10px">
                                  Data de entrada
                                </Text>
                                <FaArrowRight style={{ marginLeft: '30%', marginTop: '2%' }} color="black" size={15} />
                              </Box>
                              <Input type="date" placeholder="Entrada" />
                            </Box>
                            <Box w="48%">
                              <Text fontSize="14px" fontWeight="bold" textAlign="start" mb="10px">
                                {' '}
                                Data de saída
                              </Text>
                              <Input type="date" placeholder="Saída" />
                            </Box>
                          </InputGroup>
                          <Box
                            ml="10px"
                            display="flex"
                            flexDir={['column', 'row']}
                            alignContent="center"
                            alignItems="center"
                          >
                            <BiMoon color="#E64040" />
                            <Text ml="10px">7 noites</Text>
                          </Box>
                        </GridItem>
                        <GridItem display="flex" w="100%" alignContent="flex-start" flexDirection="row">
                          <Box display="flex" w="100%" alignContent="flex-start" flexDirection="column">
                            <Text fontSize="14px" fontWeight="bold" textAlign="start" mb="10px">
                              Quartos e hóspedes
                            </Text>
                            <Select placeholder="Selecione uma opção">
                              <option value="option1">1 quarto, 2 hospedes</option>
                            </Select>
                          </Box>
                          <Button
                            w="280px"
                            alignSelf="center"
                            fontSize="14px"
                            ml="10px"
                            color="white"
                            bgColor="theme.primary"
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick={() => {}}
                          >
                            Verificar disponibilidade
                          </Button>
                        </GridItem>
                      </Grid>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <Box borderBottomWidth={1} mt="50px" mb="50px" />
                <Center>
                  <Text fontSize="lg" fontWeight="bold" mb="50px" textTransform="uppercase">
                    {moment().locale('pt-br').format('dddd, D [de] MMMM [de] YYYY')}
                  </Text>
                </Center>
                <Grid mb="30px" templateColumns="repeat(1, 1fr)" gap={6}>
                  {loadingC ? (
                    <Center>
                      <CircularProgress mt="50px" isIndeterminate color="red" thickness="12px" />
                    </Center>
                  ) : (
                    sortBedRooms()?.map(item => (
                      <BedRoomCard
                        key={item?.id}
                        id={item?.MediaRoomId}
                        title={item?.RoomDescription}
                        dataCommons={data?.rooms}
                        dataCangoroo={item}
                        points={item?.points ?? ''}
                      />
                    ))
                  )}
                  {hotelDetailsC == [] && 'Nenhum Resultado para ser exibido'}
                </Grid>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
}
