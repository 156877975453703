// eslint-disable-next-line consistent-return
export const iconNav = language => {
  if (language == 'pt-BR') {
    return '/assets/icons/pt-br.png';
  }
  if (language == 'en') {
    return '/assets/icons/eua.png';
  }
  if (language == 'es') {
    return '/assets/icons/spanish.png';
  }
  return '/assets/icons/pt-br.png';
};

export const iconCotNav = cotation => {
  if (cotation == 'BRL') {
    return '/assets/icons/real.png';
  }
  if (cotation == 'USD') {
    return '/assets/icons/dollar.png';
  }
  if (cotation == 'POINTS') {
    return '/assets/icons/cash-club.png';
  }
  return '/assets/icons/real.png';
};

export const translates = language => {
  if (language == 'pt-BR') {
    return {
      type: 'pt-BR',
      header: { item1: 'Contato', item2: 'Ajuda', item3: 'Entrar' },
      home: {
        banner01: 'INTERCÂMBIOS PARA FÉRIAS E CLUBE DE VANTAGENS',
        banner02: 'Garantia',
        banner03: 'sempre do',
        banner04: 'melhor preço',
        boxT1: 'Vantagens da Club Share',
        boxT2: 'Pacotes em destaque',
        boxS2: 'PACOTES IMPERDÍVEIS',
        boxT3: 'Destinos mais populares',
        boxT4: 'Depoimentos',
        boxS4: 'O QUE FALAM SOBRE NÓS',
      },
      cards: {
        item1: 'diárias',
        item2: 'hóspede(s)',
        item3: 'e',
        item4: 'quarto(s)',
        item5: 'Classificação',
        item6: 'Quartos a partir de:',
        item7: 'Reservar',
        item8: 'Reservar agora',
        item9: 'Tudo incluso',
        item10: '4 hóspedes e 1 quarto',
        item11: 'SUA RESERVA',
      },
      search: {
        item1: 'Filtros rápidos',
        item2: 'Nenhum resultado encontrado! Tente outra busca.',
        item3: 'hotéis',
        item4: 'Ordenar por:',
        item5: 'Menor preço',
        item6: 'Maior preço',
      },
      searchbox: {
        tab: 'Hotéis',
        tabF: 'Hospedagem',
        default: {
          item1: 'Destino',
          item2: 'Busque aqui',
          item3: 'Estrangeiro',
          item4: 'Data de entrada',
          item5: 'Data de Saída',
          item6: 'Quartos e hospedes',
          item7: 'Quartos',
          item8: 'Adulto(s)',
          item9: 'Crianças(s)',
          item10: 'Idade',
          item11: 'Hóspedes',
          item12: 'Pesquisar',
          item13: 'Buscando...',
          item14: 'Nenhum resultado encontrado',
        },
      },
      searchBoxVoos: {
        tab: 'Voos',
        origin: 'Origem',
        destiny: 'Destino',
        departureDate: 'Data da ida',
        returnDate: 'Data da volta',
        selectOneOptions: 'Selecione uma das opções',
        goingAndReturn: 'Ida e volta',
        onlyGoing: 'Somente ida',
        quantAdultChildBaby: 'Quantidade de adultos, crianças e bebês',
        adults: 'Adultos',
        childs: 'Crianças',
        babies: 'Bebês',
        search: 'Pesquisar',
      },
      filters: {
        item1: 'Mais Filtros',
        item2: 'Menos Filtros',
        item3: 'Nome do Hotel',
        item4: 'Pesquise aqui',
        item5: 'Limpar',
        item6: 'Filtros mais usados',
        item7: 'Academia',
        item8: 'Estacionamento',
        item9: 'Internet',
        item10: 'Internet',
        item11: 'Piscina',
        item12: 'Café da manhã',
        item13: 'Lavanderia',
        item14: 'Amenidades',
        item15: 'Academia',
        item16: 'Babá ou creche',
        item17: 'Café da manhã',
        item18: 'Estacionamento',
        item19: 'Restaurante',
        item20: 'Spa',
        item21: 'Categoria',
        item22: 'Procurar',
        item23: 'Selecione um destino!',
        item24: 'Insira pelo menos 3 caracteres',
        item25: 'Aplicar',
      },
      footer: {
        box1: {
          item1: 'Precisa de ajuda da Club Share?',
          item2: 'Tem dúvidas? Atendimento 24 horas',
          item3: 'Nos ligue: (88) 8895-4545',
          item4: 'Envie email: contato@clubshare.com.br',
          item5: 'Encontre a Club Share nas mídias sociais',
        },
        box2: {
          menu1: {
            title: 'Conheça',
            item1: 'Quem somos',
            item2: 'Trabalhe conosco',
            item3: 'Seja um parceiro',
            item4: 'Seja um associado',
          },
          menu2: {
            title: 'Fale conosco',
            item1: 'Central de ajuda',
            item2: 'Política de privacidade',
            item3: 'Política de cookies',
            item4: 'Termos de uso',
          },
        },
        box3: {
          item1: 'Newsletter',
          item2: 'Cadastre o seu melhor email em nossa newsletter para receber novidades, promoções e muito mais',
          item3: 'Seu melhor email',
          item4: 'Cadastrar email',
        },
      },
    };
  }
  if (language == 'en') {
    return {
      type: 'en',
      header: { item1: 'Contact', item2: 'Help', item3: 'Login' },
      home: {
        banner01: 'EXCHANGES FOR VACATION AND ADVANTAGES CLUB',
        banner02: 'Warranty',
        banner03: 'always of',
        banner04: 'best price',
        boxT1: 'Advantages of Club Share',
        boxT2: 'Featured packages',
        boxS2: 'UNMISSSIBLE PACKAGES',
        boxT3: 'Most popular destinations',
        boxT4: 'Briefs',
        boxS4: 'WHAT THEY SAY ABOUT US',
      },
      cards: {
        item1: 'daily',
        item2: 'guest(s)',
        item3: 'and',
        item4: 'room(s)',
        item5: 'Classification',
        item6: 'Rooms from:',
        item7: 'Book',
        item8: 'Book now',
        item9: 'All inclusive',
        item10: '4 guests and 1 bedroom',
        item11: 'YOUR RESERVATION',
      },
      search: {
        item1: 'Quick filters',
        item2: 'No results found! Try another search.',
        item3: 'hotels',
        item4: 'Sort by:',
        item5: 'Lowest price',
        item6: 'Highest price',
      },
      searchbox: {
        tab: 'Hotels',
        tabF: 'Accommodation',
        default: {
          item1: 'Destination',
          item2: 'Search here',
          item3: 'Stranger',
          item4: 'Entry date',
          item5: 'Exit Date',
          item6: 'Rooms and guests',
          item7: 'Rooms',
          item8: 'Adult(s)',
          item9: 'Children(s)',
          item10: 'Age',
          item11: 'Guests',
          item12: 'Search',
          item13: 'Seeking out...',
          item14: 'No results found',
        },
      },
      searchBoxVoos: {
        tab: 'Flights',
        origin: 'Origin',
        destiny: 'Destination',
        departureDate: 'Departure Date',
        returnDate: 'Return Date',
        selectOneOptions: 'Select one of the options',
        goingAndReturn: 'Round trip',
        onlyGoing: 'One-way',
        quantAdultChildBaby: 'Number of adults, children, and babies',
        adults: 'Adults',
        childs: 'Children',
        babies: 'Babies',
        search: 'Search',
      },
      filters: {
        item1: 'More Filters',
        item2: 'Less Filters',
        item3: 'Hotel Name',
        item4: 'Search here',
        item5: 'Clear',
        item6: 'Most used filters',
        item7: 'Gym',
        item8: 'Parking',
        item9: 'Internet',
        item10: 'Internet',
        item11: 'Pool',
        item12: 'Breakfast',
        item13: 'Laundry Room',
        item14: 'Amenities',
        item15: 'Gym',
        item16: 'Nanny or day care',
        item17: 'Breakfast',
        item18: 'Parking',
        item19: 'Restaurant',
        item20: 'Spa',
        item21: 'Category',
        item22: 'Search',
        item23: 'Select a destination!',
        item24: 'Enter at least 3 characters',
        item25: 'Apply',
      },
      footer: {
        box1: {
          item1: 'Need help from Share Club?',
          item2: 'Have questions? 24-hour service',
          item3: 'Call us: (88) 8895-4545',
          item4: 'Send email: contato@clubshare.com.br',
          item5: 'Find the Share Club on social media',
        },
        box2: {
          menu1: {
            title: 'Meet',
            item1: 'Who we are',
            item2: 'Work with us',
            item3: 'Be a partner',
            item4: 'Be a member',
          },
          menu2: {
            title: 'Contact us',
            item1: 'Help Center',
            item2: 'Privacy Policy',
            item3: 'Cookies Policy',
            item4: 'Terms of Use',
          },
        },
        box3: {
          item1: 'Newsletter',
          item2: 'Register your best email in our newsletter to receive news, promotions and more',
          item3: 'Your best email',
          item4: 'Register email',
        },
      },
    };
  }
  if (language == 'es') {
    return {
      type: 'es',
      header: { item1: 'Contacto', item2: 'Ayuda', item3: 'Iniciar' },
      home: {
        banner01: 'INTERCAMBIOS POR CLUB DE VACACIONES Y VENTAJAS',
        banner02: 'Garantía',
        banner03: 'siempre de',
        banner04: 'mejor precio',
        boxT1: 'Ventajas del Club Share',
        boxT2: 'Paquetes destacados',
        boxS2: 'PAQUETES IMPERDIBLES',
        boxT3: 'Destinos más populares',
        boxT4: 'Deposiciones',
        boxS4: 'QUE DICEN DE NOSOTROS',
      },
      cards: {
        item1: 'diarios',
        item2: 'invitado(s)',
        item3: 'y',
        item4: 'habitación(es)',
        item5: 'Clasificación',
        item6: 'Habitaciones desde:',
        item7: 'Reserva',
        item8: 'Reservar ahora',
        item9: 'Todo incluido',
        item10: '4 huéspedes y 1 dormitorio',
        item11: 'TU RESERVACION',
      },
      search: {
        item1: 'Filtros rápidos',
        item2: '¡No se encontraron resultados! Prueba con otra búsqueda.',
        item3: 'hoteles',
        item4: 'Ordenar por:',
        item5: 'Precio más bajo',
        item6: 'Precio más alto',
      },
      searchbox: {
        tab: 'Hoteles',
        tabF: 'Hospedaje',
        default: {
          item1: 'Destino',
          item2: 'Buscar aquí',
          item3: 'Extranjero',
          item4: 'Fecha de entrada',
          item5: 'Fecha de salida',
          item6: 'Habitaciones e invitados',
          item7: 'Habitaciones',
          item8: 'Adulto(s)',
          item9: 'Niños(as)',
          item10: 'Edad',
          item11: 'Invitados',
          item12: 'Búsqueda',
          item13: 'Buscando...',
          item14: 'Ningún resultado encontrado',
        },
      },
      searchBoxVoos: {
        tab: 'Vuelos',
        origin: 'Origen',
        destiny: 'Destino',
        departureDate: 'Fecha de ida',
        returnDate: 'Fecha de vuelta',
        selectOneOptions: 'Seleccione una de las opciones',
        goingAndReturn: 'Ida y vuelta',
        onlyGoing: 'Solo ida',
        quantAdultChildBaby: 'Cantidad de adultos, niños y bebés',
        adults: 'Adultos',
        childs: 'Niños',
        babies: 'Bebés',
        search: 'Buscar',
      },
      filters: {
        item1: 'Más filtros',
        item2: 'Menos filtros',
        item3: 'Nombre del hotel',
        item4: 'Buscar aquí',
        item5: 'Borrar',
        item6: 'Filtros más usados',
        item7: 'Gimnasio',
        item8: 'Estacionamiento',
        ítem9: 'Internet',
        ítem10: 'Internet',
        item11: 'Piscina',
        item12: 'Desayuno',
        item13: 'Lavadero',
        item14: 'Servicios',
        item15: 'Gimnasio',
        item16: 'Niñera o guardería',
        item17: 'Desayuno',
        item18: 'Estacionamiento',
        item19: 'Restaurante',
        item20: 'Spa',
        item21: 'Categoría',
        item22: 'Buscar',
        item23: '¡Seleccione un destino!',
        item24: 'Introduzca al menos 3 caracteres',
        item25: 'Aplicar',
      },
      footer: {
        box1: {
          item1: '¿Necesita ayuda de Share Club?',
          item2: '¿Tiene preguntas? servicio 24 horas',
          item3: 'Llámenos: (88) 8895-4545',
          item4: 'Enviar correo electrónico: contato@clubshare.com.br',
          item5: 'Encuentra el Share Club en las redes sociales',
        },
        box2: {
          menu1: {
            title: 'Conocer',
            item1: 'Quienes somos',
            item2: 'Trabaja con nosotros',
            item3: 'Sé socio',
            item4: 'Ser miembro',
          },
          menu2: {
            title: 'Contáctenos',
            item1: 'Centro de ayuda',
            item2: 'Política de Privacidad',
            item3: 'Política de Cookies',
            item4: 'Términos de uso',
          },
        },
        box3: {
          item1: 'Boletín',
          item2: 'Registra tu mejor correo electrónico en nuestro boletín para recibir noticias, promociones y más',
          item3: 'Tu mejor correo electrónico',
          item4: 'Registrar correo electrónico',
        },
      },
    };
  }
  return {
    type: 'pt-BR',
    header: { item1: 'Contato', item2: 'Ajuda', item3: 'Entrar' },
    home: {
      banner01: 'INTERCÂMBIOS PARA FÉRIAS E CLUBE DE VANTAGENS',
      banner02: 'Garantia',
      banner03: 'sempre do',
      banner04: 'melhor preço',
      boxT1: 'Vantagens da Club Share',
      boxT2: 'Pacotes em destaque',
      boxS2: 'PACOTES IMPERDÍVEIS',
      boxT3: 'Destinos mais populares',
      boxT4: 'Depoimentos',
      boxS4: 'O QUE FALAM SOBRE NÓS',
    },
    cards: {
      item1: 'diárias',
      item2: 'hóspede(s)',
      item3: 'e',
      item4: 'quarto(s)',
      item5: 'Classificação',
      item6: 'Quartos a partir de:',
      item7: 'Reservar',
      item8: 'Reservar agora',
      item9: 'Tudo incluído',
      item10: '4 hóspedes e 1 quarto',
      item11: 'SUA RESERVA',
    },
    search: {
      item1: 'Filtros rápidos',
      item2: 'Nenhum resultado encontrado! Tente outra busca.',
      item3: 'hotéis',
      item4: 'Ordenar por:',
      item5: 'Menor preço',
      item6: 'Maior preço',
    },
    searchbox: {
      tab: 'Hotéis',
      tabF: 'Hospedagem',
      default: {
        item1: 'Destino',
        item2: 'Busque aqui',
        item3: 'Estrangeiro',
        item4: 'Data de entrada',
        item5: 'Data de Saída',
        item6: 'Quartos e hospedes',
        item7: 'Quartos',
        item8: 'Adulto(s)',
        item9: 'Crianças(s)',
        item10: 'Idade',
        item11: 'Hóspedes',
        item12: 'Pesquisar',
        item13: 'Buscando...',
        item14: 'Nenhum resultado encontrado',
      },
    },
    searchBoxVoos: {
      tab: 'Voos',
      origin: 'Origem',
      destiny: 'Destino',
      departureDate: 'Data da ida',
      returnDate: 'Data da volta',
      selectOneOptions: 'Selecione uma das opções',
      goingAndReturn: 'Ida e volta',
      onlyGoing: 'Somente ida',
      quantAdultChildBaby: 'Quantidade de adultos, crianças e bebês',
      adults: 'Adultos',
      childs: 'Crianças',
      babies: 'Bebês',
      search: 'Pesquisar',
    },
    filters: {
      item1: 'Mais Filtros',
      item2: 'Menos Filtros',
      item3: 'Nome do Hotel',
      item4: 'Pesquise aqui',
      item5: 'Limpar',
      item6: 'Filtros mais usados',
      item7: 'Academia',
      item8: 'Estacionamento',
      item9: 'Internet',
      item10: 'Internet',
      item11: 'Piscina',
      item12: 'Café da manhã',
      item13: 'Lavanderia',
      item14: 'Amenidades',
      item15: 'Academia',
      item16: 'Babá ou creche',
      item17: 'Café da manhã',
      item18: 'Estacionamento',
      item19: 'Restaurante',
      item20: 'Spa',
      item21: 'Categoria',
      item22: 'Procurar',
      item23: 'Selecione um destino!',
      item24: 'Insira pelo menos 3 caracteres',
      item25: 'Aplicar',
    },
    footer: {
      box1: {
        item1: 'Precisa de ajuda da Club Share?',
        item2: 'Tem dúvidas? Atendimento 24 horas',
        item3: 'Nos ligue: (88) 8895-4545',
        item4: 'Envie email: contato@clubshare.com.br',
        item5: 'Encontre a Club Share nas mídias sociais',
      },
      box2: {
        menu1: {
          title: 'Conheça',
          item1: 'Quem somos',
          item2: 'Trabalhe conosco',
          item3: 'Seja um parceiro',
          item4: 'Seja um associado',
        },
        menu2: {
          title: 'Fale conosco',
          item1: 'Central de ajuda',
          item2: 'Política de privacidade',
          item3: 'Política de cookies',
          item4: 'Termos de uso',
        },
      },
      box3: {
        item1: 'Newsletter',
        item2: 'Cadastre o seu melhor email em nossa newsletter para receber novidades, promoções e muito mais',
        item3: 'Seu melhor email',
        item4: 'Cadastrar email',
      },
    },
  };
};
