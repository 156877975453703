import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Image,
  Input,
  Select,
  Text,
  InputGroup,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Card,
  CardBody,
  Switch,
  Radio,
  RadioGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { BsArrowLeft, BsArrowRight, BsCheck } from 'react-icons/bs';
import { Controller, useForm } from 'react-hook-form';
import { useSearch } from '../../providers/SearchProvider';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useCotation } from '../../providers/CotationProvider';
import { apiCPT } from '../../services/api';
import 'react-phone-number-input/style.css';
import 'react-credit-cards/es/styles-compiled.css';
import CardPay from 'react-credit-cards';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../../utils/payments';
import { rGrid2, rGrid3 } from '../../utils/responsiveSizes';
import ReactInputMask from 'react-input-mask';
import countryList from 'react-select-country-list';
import PhoneInputWithCountrySelect, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function StepsCheckout({ step, prevStep, nextStep, dataCangooroo, sR }) {
  moment.locale('pt-br');

  const { cotation, points, markupC, isCotation, setIsCotation } = useCotation();
  const navigate = useNavigate();

  const openInNewTab = () => {
    // Redirecionar a contato/suporte, não será assim nesse momento
    // window.open(`${location.origin}/contact`, '_blank', 'noreferrer')

    // Redirecionar diretamente ao whatsapp
    window.open(`https://api.whatsapp.com/send?phone=556230892500`, '_blank', 'noreferrer');
  };

  const {
    getBookingConditions,
    doBookingPolice,
    doBooking,
    paymentData,
    loadingC,
    getPlayBooking,
    getPlayBookingPoints,
    loadingB,
    loadingCancel,
    bookingId,
    showCancelButton,
    errorPlayBook,
    bookError,
  } = useSearch();

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const [cardInfo, setCardInfo] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: '',
  });

  const [updateForm, setUpdateForm] = useState(true);
  const [priceCard, setPriceCard] = useState(0);
  const [pricePoints, setPricePoints] = useState(0);
  const [isCard, setIsCard] = useState(true);
  const [isPoints, setIsPoints] = useState(false);
  const [isPointsCard, setIsPointsCard] = useState(false);
  const [cashClubs, setCashClubs] = useState({});
  const [userData, setUserData] = useState([]);
  const [radio, setRadio] = React.useState('1');

  const hotelDetails = JSON.parse(localStorage.getItem('_hotelDetails'));
  const tokenStorage = JSON.parse(localStorage.getItem('__booking_filters'));

  // Verifica se está a utilizar CashClubs nacionais ou internacionais
  const isInter =
    hotelDetails?.destination?.countryId === 'BR' ? Math.floor(cashClubs?.saldoN) : Math.floor(cashClubs?.saldoI);

  const cotationPoints = () => {
    if (doBookingPolice?.Rooms && !loadingC) {
      const base = doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value;
      const cot = points;
      const calc = Math.ceil(base * cot);
      return calc;
    }
  };

  const typeCotationCC = () => {
    const base = dataCangooroo?.TotalSellingPrice?.Value;
    const cot = points;
    const calc = Math.ceil(base * cot);
    return calc;
  };

  const handlePointsInPrice = val => {
    const totalPrice = typeCotationCC();
    const cot = points;
    const calc = totalPrice - val;
    const result = Math.ceil(calc / cot);

    return result;
  };

  const isEnough = isInter - cotationPoints() >= 0;

  const typeCotationUSD = () => {
    if (isCotation == 'USD') {
      const base = doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value;
      const cot = cotation;
      const calc = base / cot;
      const cotMarkup = calc * (markupC / 100);
      const result = Math.ceil(cotMarkup + calc);
      return result?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  };
  const onSubmit = async values => {
    if (isCard) {
      if (userData.vindi_customer_id === 'NULL') {
      } else {
        await getPlayBooking({
          dataForm: values,
          customer_id: userData.vindi_customer_id,
          hotelId: hotelDetails?.id,
          roomId: doBookingPolice?.Rooms[0]?.Id,
          user_id: userData?.user_id,
          token: `${tokenStorage?.token}`,
        });
      }
    }
    if (isPoints) {
      if (Number(priceCard) > doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value) {
        alert('O valor não pode ser maior que o da reserva!');
      }
      if (!isEnough && !isPointsCard) {
        alert(
          'Somente seu saldo de Viajex não é suficiente para esta reserva, tente complementar com o cartão ou solicitar um novo depósito!',
        );
      }
      if (isPointsCard && Math.ceil(parseFloat(priceCard)) * cotation + isInter <= cotationPoints()) {
        alert(
          'Seu saldo e complemento inserido no momento ainda é menor que o custo da sua reserva, preencha corretamente os campos com valores equivalentes a cobrança final',
        );
      }
      if (isPoints && !isPointsCard) {
        await getPlayBookingPoints({
          dataForm: values,
          customer_id: userData.vindi_customer_id,
          hotelId: hotelDetails?.id,
          roomId: doBookingPolice?.Rooms[0]?.Id,
          user_id: userData?.user_id,
          token: `${tokenStorage?.token}`,
          useCard: false,
          pricePoints: typeCotationCC(),
        });
      } else {
        const cotMarkup = priceCard * (markupC / 100);
        const result = Math.ceil(cotMarkup);
        await getPlayBookingPoints({
          dataForm: values,
          customer_id: userData.vindi_customer_id,
          hotelId: hotelDetails?.id,
          roomId: doBookingPolice?.Rooms[0]?.Id,
          user_id: userData?.user_id,
          token: `${tokenStorage?.token}`,
          useCard: true,
          priceCard: cotation === 'USD' ? result : Number(priceCard),
          pricePoints: pricePoints,
          // pricePoints:
          //   Math.ceil(doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value * cotation) - Number(priceCard) * cotation,
        });
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('__user_data')) {
      setUserData(JSON.parse(localStorage.getItem('__user_data')));
    }
  }, []);

  const getBalance = async () => {
    try {
      const res = await apiCPT.get(`v1/cashclubs/pointsbalance/${userData.user_id}`);
      if (res.status === 200) {
        setCashClubs(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userData?.user_id !== undefined) {
      getBalance();
    }
  }, [userData?.user_id]);

  useEffect(() => {
    getBookingConditions({
      hotelId: hotelDetails?.id,
      roomId: dataCangooroo?.Id,
      token: `${tokenStorage?.token}`,
    });
  }, []);

  const handleInputChange = (e, name) => {
    if (name === 'number') {
      e.target.value = formatCreditCardNumber(e.target.value);
    } else if (name === 'expiry') {
      e.target.value = formatExpirationDate(e.target.value);
    } else if (name === 'cvc') {
      e.target.value = formatCVC(e.target.value);
    }
    setCardInfo(old => ({
      ...old,
      [name]: e?.target?.value,
    }));
  };

  const arr = new Array(sR[0].numberOfAdults[0]).fill('').map((_, i) => i);
  const arrC = new Array(sR[0].childrenAge?.length).fill('').map((_, i) => i);

  const array1 = arr.map(n => n);
  const array2 = arrC.map(n => n);

  const handleInputFocus = name => {
    setCardInfo(old => ({
      ...old,
      focused: name,
    }));
  };

  useEffect(() => {
    trigger();
  }, []);

  moment.locale('pt-br');

  const options = useMemo(() => countryList().getData(), []);

  const dateRangeErrorMessage = `Campo inválido, o responsável deve ser maior de idade`;
  const dateRangeErrorMessageC = `Campo inválido, A idade não condiz com a de uma criança, ou informada inicialmente`;

  const srcCep = async (zipcode: string, idx: number) => {
    try {
      const res = await axios.get(`https://cdn.apicep.com/file/apicep/${zipcode}.json`);
      if (res?.status === 200) {
        if (res?.data?.city) {
          setValue(`data.[${idx}].City`, res?.data?.city);
        }
        if (res?.data?.address || res?.data?.district) {
          setValue(`data.[${idx}].Address`, `${res?.data?.address}, ${res?.data?.district}`);
        }
        if (res?.data?.state) {
          setValue(`data.[${idx}].State`, res?.data?.state);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (updateForm) {
      setUpdateForm(false);
    }
  }, []);

  const formatCPF = cpf => {
    //retira os caracteres indesejados...
    cpf = cpf?.replace(/[^\d]/g, '');

    // realizar a formatação...
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatCEP = cep => {
    //retira os caracteres indesejados...
    cep = cep?.replace(/[^\d]/g, '');

    //realizar a formatação...
    return cep
      ?.replace(/\D/g, '')
      ?.replace(/(\d{5})(\d)/, '$1-$2')
      ?.replace(/(-\d{3})\d+?$/, '$1');
  };
  useEffect(() => {
    if (userData) {
      setValue(`data.[0].Title`, 'Outro');
      setValue(`data.[0].Name`, `${userData?.user_firstname}`);
      setValue(`data.[0].Surname`, `${userData?.user_lastname}`);
      setValue(`data.[0].Email`, `${userData?.email}`);
      setValue(`data.[0].BirthDate`, `${userData?.user_birthdate}`);
      setValue(`data.[0].Address`, `${userData?.user_address}, ${userData?.user_address_neighborhood}`);
      setValue(`data.[0].AddressNumber`, `${userData?.user_address_number}`);
      setValue(`data.[0].State`, `${userData?.user_address_state}`);
      setValue(`data.[0].AddressComplement`, `${userData?.user_address_complement}`);
      setValue(`data.[0].City`, `${userData?.user_address_city}`);
      setValue(`data.[0].Phone`, formatPhoneNumberIntl(`+${userData?.user_telephone}`));
      setValue(`data.[0].Age`, `${moment().diff(moment(userData?.user_birthdate, 'YYYY-MM-DD'), 'years')}`);
      setValue(`data.[0].Country`, 'Brazil');
    }
  }, [userData, updateForm === true]);

  const errorStyle = { color: 'red', fontSize: 10 };

  const valueCotation = base => {
    if (isCotation == 'USD') {
      const cot = cotation;
      const calc = base / cot;
      const cotMarkup = calc * (markupC / 100);
      const result = Math.ceil(cotMarkup + calc);
      return result?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    if (isCotation == 'BRL') {
      return base?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
    if (isCotation == 'POINTS') {
      const cot = points;
      const calc = Math.ceil(base * cot);
      return calc;
    }
    return base?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  };

  const switchTabs = () => {
    switch (step) {
      case 1:
        return (
          <>
            {!loadingC && doBookingPolice?.Rooms && doBookingPolice?.Rooms.length > 0 && (
              <Box display="flex" minWidth={['100%', '100%', '700px', '800px']} p="20px" h="100%" flexDir="column">
                {doBookingPolice?.Rooms[0] && (
                  <Box minH="98%">
                    <Text mb="20px" fontSize="20px" fontWeight="bold">
                      Termos e condições
                    </Text>
                    {!loadingC &&
                      doBookingPolice?.Rooms &&
                      doBookingPolice?.Rooms.length > 0 &&
                      doBookingPolice?.Rooms[0]?.MoreInformation && (
                        <div
                          style={{ marginLeft: 25 }}
                          dangerouslySetInnerHTML={{ __html: doBookingPolice?.Rooms[0]?.MoreInformation }}
                        />
                      )}
                    <Text mt="90px" fontSize="28px" color="red">
                      <strong> Tarifa com restrição: </strong>Quanto custa para eu cancelar?
                    </Text>
                    <Text mt="30px" fontSize="18px" color="red">
                      A partir de{' '}
                      {moment(doBookingPolice?.Rooms[0]?.CancellationPolicies[0]?.StartDate).isAfter(moment()) ? (
                        <strong>
                          {moment(doBookingPolice?.Rooms[0]?.CancellationPolicies[0]?.StartDate).format('LLLL')}
                        </strong>
                      ) : (
                        ''
                      )}{' '}
                      o cancelamento fica sujeito a pagamento de{' '}
                      <strong>
                        {valueCotation(Math.ceil(doBookingPolice?.Rooms[0]?.CancellationPolicies[0]?.Value?.Value))}
                      </strong>
                    </Text>

                    {!loadingC && !doBookingPolice?.Rooms[0]?.MoreInformation && (
                      <Text mt="90px" fontSize="26px">
                        Siga Abaixo para avançar para efetuar sua reserva
                      </Text>
                    )}
                  </Box>
                )}

                <Box
                  mt={['20px', '10px', '0px', '0px']}
                  justifySelf="flex-end"
                  display="flex"
                  flexDir="row"
                  justifyContent="flex-end"
                >
                  <Button type="submit" onClick={() => nextStep()} colorScheme="green">
                    Avançar
                    <BsArrowRight style={{ marginLeft: 10 }} />
                  </Button>
                </Box>
              </Box>
            )}
          </>
        );
      case 2:
        return (
          <>
            <Box display="flex" minWidth={['95%', '95%', '700px', '800px']} p="20px" h="100%" flexDir="column">
              <>
                {array1?.map((item, idx) => (
                  <Accordion
                    defaultIndex={[0]}
                    w={['95%', '95%', '100%', '45vw']}
                    allowMultiple
                    key={`${item.id}-${idx}`}
                  >
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            <Text mb="20px" fontSize="20px" fontWeight="bold">
                              Adulto {idx + 1}: {idx === 0 ? 'hóspede principal' : 'hóspede secundário'}
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>

                      <AccordionPanel pb={4}>
                        <Box minH="98%">
                          <Grid mt="30px" templateColumns={rGrid3} gap={6}>
                            {idx === 0 && (
                              <GridItem display="flex" flexDir="column">
                                <Text fontWeight="bold">Título*</Text>
                                <Select
                                  defaultValue="Outro"
                                  {...register(`data.[${idx}].Title`, { required: true, maxLength: 20 })}
                                  placeholder="Selecione "
                                >
                                  <option value="Sr">Sr</option>
                                  <option value="Sra">Sra</option>
                                  <option value="Mr">Mr</option>
                                  <option value="Ms">Ms</option>
                                  <option value="Outro">Outro</option>
                                </Select>

                                {errors?.data?.length > 0 && errors?.data[`${idx}`]?.Title && (
                                  <p role="alert" style={errorStyle}>
                                    Este campo é Obrigatório
                                  </p>
                                )}
                              </GridItem>
                            )}

                            <GridItem display="flex" flexDir="column">
                              <Text fontWeight="bold">Nome*</Text>

                              <Input
                                autoFocus
                                required
                                disabled={idx === 0}
                                isDisabled={idx === 0}
                                {...register(`data.[${idx}].Name`, { required: true, maxLength: 20 })}
                                placeholder="Nome"
                              />
                              {errors?.data?.length > 0 && errors?.data[`${idx}`]?.Name && (
                                <p role="alert" style={errorStyle}>
                                  Este campo é Obrigatório
                                </p>
                              )}
                            </GridItem>

                            <GridItem display="flex" flexDir="column">
                              <Text fontWeight="bold">Sobrenome*</Text>
                              <Input
                                autoFocus
                                required
                                disabled={idx === 0}
                                isDisabled={idx === 0}
                                {...register(`data.[${idx}].Surname`, { required: true, maxLength: 20 })}
                                placeholder="Sobrenome"
                              />
                              {errors?.data?.length > 0 && errors?.data[`${idx}`]?.Surname && (
                                <p role="alert" style={errorStyle}>
                                  Este campo é Obrigatório
                                </p>
                              )}
                            </GridItem>
                          </Grid>
                          <Grid mt="30px" templateColumns={rGrid3} gap={6}>
                            {idx === 0 && (
                              <>
                                <GridItem display="flex" flexDir="column">
                                  <Text fontWeight="bold">Documento*</Text>
                                  <Select
                                    disabled
                                    isDisabled
                                    defaultChecked
                                    defaultValue="cpf"
                                    {...register(`data.[${idx}].DocumentType`, { required: true })}
                                    placeholder="Selecione "
                                  >
                                    <option value="cpf">CPF</option>
                                  </Select>
                                  {errors?.data?.length > 0 && errors?.data[`${idx}`]?.DocumentType && (
                                    <p role="alert" style={errorStyle}>
                                      Este campo é Obrigatório
                                    </p>
                                  )}
                                </GridItem>

                                <GridItem display="flex" flexDir="column">
                                  <Text fontWeight="bold">Digite o documento*</Text>
                                  <Input
                                    disabled
                                    isDisabled
                                    defaultValue={userData?.user_cpf ? formatCPF(userData?.user_cpf) : ''}
                                    as={ReactInputMask}
                                    mask="999.999.999-99"
                                    {...register(`data.[${idx}].DocumentNumber`, { required: true })}
                                    placeholder="Digite o documento"
                                  />
                                  {errors?.data?.length > 0 && errors?.data[`${idx}`]?.DocumentNumber && (
                                    <p role="alert" style={errorStyle}>
                                      Este campo é Obrigatório
                                    </p>
                                  )}
                                </GridItem>
                              </>
                            )}

                            <GridItem display="flex" flexDir="column">
                              <Text fontWeight="bold">Idade*</Text>
                              <Input
                                disabled
                                isDisabled
                                {...register(`data.[${idx}].Age`, {
                                  required: true,
                                  min: 18,
                                  max: 99,
                                  valueAsNumber: true,
                                })}
                                placeholder="Exemplo: 21"
                              />
                              {errors?.data?.length > 0 && errors?.data[`${idx}`]?.Age && (
                                <p role="alert" style={errorStyle}>
                                  Este campo é Obrigatório
                                </p>
                              )}
                            </GridItem>
                            <GridItem display="flex" flexDir="column">
                              <Text fontWeight="bold">Data de nascimento*</Text>
                              <Input
                                type="date"
                                disabled={idx === 0}
                                isDisabled={idx === 0}
                                {...register(`data.[${idx}].BirthDate`, {
                                  required: true,
                                  valueAsDate: true,
                                  onChange: e => {
                                    setValue(
                                      `data.[${idx}].Age`,
                                      `${moment().diff(moment(e.currentTarget.value, 'YYYY-MM-DD'), 'years')}`,
                                    );
                                  },
                                  validate: date =>
                                    moment().diff(moment(date, 'YYYY-MM-DD'), 'years') >= 18 || dateRangeErrorMessage,
                                })}
                                placeholder="Exemplo: 15/09/1989"
                              />
                              {errors?.data?.length > 0 && errors?.data[`${idx}`]?.BirthDate && (
                                <p role="alert" style={errorStyle}>
                                  {dateRangeErrorMessage}
                                </p>
                              )}
                            </GridItem>
                          </Grid>
                          {idx === 0 && (
                            <Grid mt="30px" templateColumns={rGrid2} gap={6}>
                              <GridItem display="flex" flexDir="column">
                                <Text fontWeight="bold">Email*</Text>
                                <Input
                                  disabled={idx === 0}
                                  isDisabled={idx === 0}
                                  {...register(`data.[${idx}].Email`, { required: true, pattern: /^\S+@\S+$/i })}
                                  type="email"
                                  placeholder="Seu melhor email"
                                />
                                {errors?.data?.length > 0 && errors?.data[`${idx}`]?.Email && (
                                  <p role="alert" style={errorStyle}>
                                    Este campo é Obrigatório
                                  </p>
                                )}
                              </GridItem>

                              <GridItem display="flex" flexDir="column">
                                <Text fontWeight="bold">Telefone para contato*</Text>
                                <InputGroup>
                                  <Controller
                                    name={`data.[${idx}].Phone`}
                                    control={control}
                                    rules={{
                                      validate: value => isValidPhoneNumber(value) || 'Número de telefone inválido',
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                      <PhoneInputWithCountrySelect
                                        flags={flags}
                                        value={value}
                                        onChange={onChange}
                                        international
                                        defaultCountry="BR"
                                        bg="white"
                                        readOnly
                                        placeholder="Insira o telefone"
                                      />
                                    )}
                                  />
                                </InputGroup>
                                {errors?.data?.length > 0 && errors?.data[`${idx}`]?.Phone && (
                                  <p role="alert" style={errorStyle}>
                                    Campo inválido
                                  </p>
                                )}
                              </GridItem>
                            </Grid>
                          )}
                          {idx === 0 && (
                            <>
                              <Text mb="20px" mt="20px" fontSize="20px" fontWeight="bold">
                                Endereço
                              </Text>
                              <Grid mt="30px" alignItems="center" templateColumns={rGrid3} gap={6}>
                                <GridItem display="flex" flexDir="column">
                                  <Text fontWeight="bold">CEP</Text>
                                  <Input
                                    disabled={idx === 0}
                                    isDisabled={idx === 0}
                                    defaultValue={
                                      userData?.user_address_zipcode ? formatCEP(userData?.user_address_zipcode) : ''
                                    }
                                    as={ReactInputMask}
                                    mask="99999-999"
                                    {...register(`data.[${idx}].ZipCode`, {
                                      required: true,
                                      onChange: e => {
                                        if (e.currentTarget.value?.replace(/\D/g, '').length >= 8) {
                                          srcCep(e.currentTarget.value, idx);
                                        }
                                      },
                                    })}
                                    placeholder="XXXXXX-XXX"
                                  />
                                  {errors?.data?.length > 0 && errors?.data[`${idx}`]?.ZipCode && (
                                    <p role="alert" style={errorStyle}>
                                      Este campo é Obrigatório
                                    </p>
                                  )}
                                </GridItem>

                                <GridItem display="flex" flexDir="column">
                                  <Text fontSize="13px" fontWeight="bold">
                                    Caso não saiba, clique no botão ao lado
                                  </Text>
                                </GridItem>

                                <GridItem display="flex" flexDir="column">
                                  <Button
                                    ml="20px"
                                    onClick={() =>
                                      window.open(
                                        'https://buscacepinter.correios.com.br/app/endereco/index.php',
                                        '_blank',
                                      )
                                    }
                                  >
                                    Não sei meu CEP
                                  </Button>
                                </GridItem>
                              </Grid>

                              <Grid mt="30px" templateColumns={rGrid2} gap={6}>
                                <GridItem display="flex" flexDir="column">
                                  <Text fontWeight="bold">Endereço</Text>
                                  <Input
                                    disabled={idx === 0}
                                    isDisabled={idx === 0}
                                    type="text"
                                    {...register(`data.[${idx}].Address`)}
                                    placeholder="Digite seu endereço"
                                  />
                                </GridItem>

                                <GridItem display="flex" flexDir="column">
                                  <Text fontWeight="bold">Número</Text>
                                  <Input
                                    disabled={idx === 0}
                                    isDisabled={idx === 0}
                                    type="number"
                                    {...register(`data.[${idx}].AddressNumber`, {
                                      required: true,
                                      valueAsNumber: true,
                                    })}
                                    placeholder="999"
                                  />
                                </GridItem>
                              </Grid>
                              <Grid mt="30px" templateColumns="repeat(1, 1fr)" gap={6}>
                                <GridItem display="flex" flexDir="column">
                                  <Text fontWeight="bold">Complemento do endereço</Text>
                                  <Input
                                    disabled={idx === 0}
                                    isDisabled={idx === 0}
                                    {...register(`data.[${idx}].AddressComplement`)}
                                    placeholder="Qd. 99, Lt. 99"
                                  />
                                </GridItem>
                              </Grid>

                              <Grid mt="30px" alignItems="center" templateColumns={rGrid2} gap={6}>
                                <GridItem display="flex" flexDir="column">
                                  <Text fontWeight="bold">Estado</Text>
                                  <Input
                                    disabled={idx === 0}
                                    isDisabled={idx === 0}
                                    {...register(`data.[${idx}].State`)}
                                    placeholder="Ex: Goiás"
                                  />
                                </GridItem>

                                <GridItem display="flex" flexDir="column">
                                  <Text fontWeight="bold">Cidade</Text>
                                  <Input
                                    disabled={idx === 0}
                                    isDisabled={idx === 0}
                                    {...register(`data.[${idx}].City`)}
                                    placeholder="Ex: Goiânia"
                                  />
                                </GridItem>
                              </Grid>
                              <Grid mt="30px" templateColumns="repeat(1, 1fr)" gap={6}>
                                <GridItem display="flex" flexDir="column">
                                  <Text fontWeight="bold">País</Text>
                                  <Select
                                    disabled={idx === 0}
                                    isDisabled={idx === 0}
                                    defaultValue="Brazil"
                                    defaultChecked="Brazil"
                                    defaultCountry="Brazil"
                                    zIndex={999}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    options={options}
                                    countryCode="BR"
                                    w="50%"
                                    {...register(`data.[${idx}].Country`)}
                                  >
                                    {options?.map(country => (
                                      <option key={country?.value} value={country.label}>
                                        {country.label}
                                      </option>
                                    ))}
                                  </Select>
                                </GridItem>
                              </Grid>
                            </>
                          )}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                ))}
                <>
                  {
                    <>
                      {array2?.length !== 0 && (
                        <>
                          {array2?.map((item, idx) => (
                            <Accordion w={['95%', '95%', '100%', '45vw']} allowMultiple key={item.id}>
                              <AccordionItem>
                                <h2>
                                  <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                      <Text mb="20px" fontSize="20px" fontWeight="bold">
                                        Criança {idx + 1}:{' '}
                                        {idx === 0 ? 'Informação da criança' : 'Informação da criança'}
                                      </Text>
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>

                                <AccordionPanel pb={4}>
                                  <Box minH="98%">
                                    <Grid mt="30px" templateColumns={rGrid3} gap={6}>
                                      <GridItem display="flex" flexDir="column">
                                        <Text fontWeight="bold">Nome*</Text>

                                        <Input
                                          autoFocus
                                          required
                                          {...register(`dataC.[${idx}].Name`, { required: true, maxLength: 20 })}
                                          placeholder="Nome"
                                        />
                                        {errors?.dataC?.length > 0 && errors?.dataC[`${idx}`]?.Name && (
                                          <p role="alert" style={errorStyle}>
                                            Este campo é Obrigatório
                                          </p>
                                        )}
                                      </GridItem>

                                      <GridItem display="flex" flexDir="column">
                                        <Text fontWeight="bold">Sobrenome*</Text>
                                        <Input
                                          autoFocus
                                          required
                                          disabled={idx === 0}
                                          isDisabled={idx === 0}
                                          {...register(`dataC.[${idx}].Surname`, { required: true, maxLength: 20 })}
                                          placeholder="Sobrenome"
                                        />
                                        {errors?.dataC?.length > 0 && errors?.dataC[`${idx}`]?.Surname && (
                                          <p role="alert" style={errorStyle}>
                                            Este campo é Obrigatório
                                          </p>
                                        )}
                                      </GridItem>
                                    </Grid>

                                    <Grid mt="30px" templateColumns={rGrid3} gap={6}>
                                      <GridItem hidden display="flex" flexDir="column">
                                        <Text fontWeight="bold">é criança?*</Text>
                                        <Select
                                          defaultChecked
                                          defaultValue="true"
                                          {...register(`dataC.[${idx}].isChild`)}
                                          placeholder="Selecione "
                                        >
                                          <option value="true">Criança?</option>
                                        </Select>
                                      </GridItem>

                                      <GridItem display="flex" flexDir="column">
                                        <Text fontWeight="bold">Idade*</Text>
                                        <Input
                                          disabled
                                          {...register(`dataC.[${idx}].Age`, {
                                            required: true,
                                            min: 0,
                                            max: 99,
                                            valueAsNumber: true,
                                          })}
                                          placeholder="Exemplo: 21"
                                        />
                                        {errors?.dataC?.length > 0 && errors?.dataC[`${idx}`]?.Age && (
                                          <p role="alert" style={errorStyle}>
                                            Este campo é Obrigatório
                                          </p>
                                        )}
                                      </GridItem>
                                      <GridItem display="flex" flexDir="column">
                                        <Text fontWeight="bold">Data de nascimento*</Text>
                                        <Input
                                          type="date"
                                          {...register(`dataC.[${idx}].BirthDate`, {
                                            required: true,
                                            valueAsDate: true,
                                            onChange: e => {
                                              setValue(
                                                `dataC.[${idx}].Age`,
                                                `${moment().diff(
                                                  moment(e.currentTarget.value, 'YYYY-MM-DD'),
                                                  'years',
                                                )}`,
                                              );
                                            },
                                            validate: date =>
                                              (moment().diff(moment(date, 'YYYY-MM-DD'), 'years') <= 18 &&
                                                moment().diff(moment(date, 'YYYY-MM-DD'), 'years') ==
                                                  sR[0].childrenAge[idx]) ||
                                              dateRangeErrorMessageC,
                                          })}
                                          placeholder="Exemplo: 15/09/1989"
                                        />
                                        {errors?.dataC?.length > 0 && errors?.dataC[`${idx}`]?.BirthDate && (
                                          <p role="alert" style={errorStyle}>
                                            {dateRangeErrorMessageC}
                                          </p>
                                        )}
                                      </GridItem>
                                    </Grid>
                                  </Box>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          ))}
                        </>
                      )}
                    </>
                  }
                </>
              </>
              {Object.keys(errors)?.length !== 0 && (
                <Box alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                  <Alert status="error">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>Atenção!</AlertTitle>
                      <AlertDescription>
                        Preencha todos campos necessários de forma correta para prosseguir adiante
                      </AlertDescription>
                    </Box>
                  </Alert>
                </Box>
              )}
            </Box>

            <Box
              w={['95%', '95%', '98%', '100%']}
              justifySelf="flex-end"
              display="flex"
              flexDir="row"
              pr={['10px', '0px', '0px', '0px']}
              pl={['25px', '0px', '0px', '0px']}
              justifyContent="space-between"
            >
              <Button onClick={() => prevStep()} variant="outline">
                <BsArrowLeft style={{ marginRight: 10 }} />
                Voltar
              </Button>

              <Button
                disabled={Object.keys(errors)?.length !== 0}
                onClick={() => (Object.keys(errors)?.length === 0 ? nextStep() : null)}
                colorScheme="green"
              >
                Avançar
                <BsArrowRight style={{ marginLeft: 10 }} />
              </Button>
            </Box>
          </>
        );
      case 3:
        return (
          <Box display="flex" w="35vw" minWidth={['100%', '100%', '700px', '800px']} p="20px" h="100%" flexDir="column">
            <Box minH="98%">
              <Text mb="20px" fontSize="20px" fontWeight="bold">
                Processo de pagamento
              </Text>

              <Card>
                <CardBody>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" justifyContent="space-between" flexDir="row">
                      <Switch
                        defaultChecked={isPoints}
                        defaultValue={isPoints}
                        value={isPoints}
                        onChange={() => {
                          if (radio === '1') {
                            localStorage.setItem('cotation', JSON.stringify('POINTS'));
                            setIsCotation('POINTS');
                          }
                          setIsPoints(!isPoints);
                          setIsCard(!isCard);
                          setIsPointsCard(false);
                        }}
                        colorScheme="green"
                        size="md"
                      />
                      <Text ml="10px"> Quero utilizar minha carteira de Viajex</Text>{' '}
                    </Box>
                    <Box display="flex" flexDir="row">
                      <Text color="red">
                        {typeCotationCC()}
                        {hotelDetails?.destination?.countryId === 'BR' ? ' Viajex N' : ' Viajex I'}
                      </Text>
                      <Image ml="5px" h="25px" src="/assets/icons/cash-club.png" />
                    </Box>
                  </Box>
                </CardBody>
              </Card>

              <RadioGroup isDisabled={!isPoints} onChange={setRadio} value={radio} defaultValue="1">
                <Box
                  mt="30px"
                  justifySelf={['center', 'center', 'flex-end', 'flex-end']}
                  display="flex"
                  flexDir={['column', 'column', 'row', 'row']}
                  justifyContent={['center', 'center', 'space-between', 'space-between']}
                >
                  <Card
                    onClick={async () => {
                      if (isPoints) {
                        await setIsCard(false);
                        await setIsPoints(true);
                        await setIsPointsCard(false);
                        await setRadio('1');
                        localStorage.setItem('cotation', JSON.stringify('POINTS'));
                        setIsCotation('POINTS');
                      } else {
                        return;
                      }
                    }}
                    cursor="pointer"
                    bg={isPoints && !isPointsCard && '#EBF8FF'}
                    borderWidth={isPoints && !isPointsCard && 1}
                    borderColor={isPoints && !isPointsCard && '#63B3ED'}
                    mb={['15px', '15px', '0px', '0px']}
                    mr={['0px', '0px', '15px', '15px']}
                  >
                    <CardBody>
                      <Box display="flex" justifyContent="space-between" flexDir="row">
                        <Box display="flex" justifyContent="space-between" flexDir="row">
                          <Radio value="1">
                            Quero pagar com <strong>todo</strong> o meu saldo de Viajex
                          </Radio>
                        </Box>
                        <Box display="flex" flexDir="row">
                          <Image ml="5px" h="25px" src="/assets/icons/cash-club.png" />
                        </Box>
                      </Box>
                    </CardBody>
                  </Card>
                  <Card
                    onClick={async () => {
                      if (isPoints) {
                        await setIsCard(false);
                        await setIsPoints(true);
                        await setIsPointsCard(true);
                        await setRadio('2');
                        localStorage.setItem('cotation', JSON.stringify('BRL'));
                        setIsCotation('BRL');
                      } else {
                        return;
                      }
                    }}
                    cursor="pointer"
                    bg={isPointsCard && '#EBF8FF'}
                    borderWidth={isPointsCard && 1}
                    borderColor={isPointsCard && '#63B3ED'}
                    ml={['0px', '0px', '15px', '15px']}
                  >
                    <CardBody>
                      <Box display="flex" justifyContent="space-between" flexDir="row">
                        <Box display="flex" justifyContent="space-between" flexDir="row">
                          <Radio value="2">
                            Quero pagar apenas uma <strong>parte</strong> da reserva com meu saldo de Viajex
                          </Radio>
                        </Box>
                        <Box display="flex" flexDir="row"></Box>
                      </Box>
                    </CardBody>
                  </Card>
                </Box>
              </RadioGroup>
              {isInter - typeCotationCC() >= 0 && (
                <>
                  <Box alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                    <Alert status="success">
                      <AlertIcon />
                      <Box>
                        <AlertTitle>Você pode abater o valor total da operação</AlertTitle>
                        <AlertDescription>
                          Sua quantidade de Viajex é suficiente para abater o valor total desta operação
                        </AlertDescription>
                      </Box>
                    </Alert>
                  </Box>
                </>
              )}
              {isInter - typeCotationCC() < 0 && (
                <>
                  <Box alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                    <Alert status="warning">
                      <AlertIcon />
                      <Box>
                        <AlertTitle>Viajex insuficientes!</AlertTitle>
                        <AlertDescription>
                          Seu saldo total de Viajex é insuficiente para abater o valor total desta operação
                        </AlertDescription>
                      </Box>
                    </Alert>
                  </Box>
                </>
              )}

              {(isPointsCard || isCard) && (
                <>
                  <Box mt="40px">
                    {isPointsCard && (
                      <>
                        <Text fontWeight="black" mb="20px">
                          Quero usar esta quantidade de Viajex:
                        </Text>
                        <Box
                          display="flex"
                          w={['100%', '100%', '60%', '60%']}
                          flexDir="row"
                          alignItems="center"
                          justifyContent="center"
                          mb="20px"
                        >
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              color="gray.300"
                              fontSize="1.2em"
                              // eslint-disable-next-line react/no-children-prop
                              children={<Image ml="5px" h="20px" src="/assets/icons/cash-club.png" />}
                            />
                            <Input
                              defaultValue={pricePoints ?? 0}
                              onChange={e => {
                                setPriceCard(handlePointsInPrice(e?.currentTarget?.value));
                                setPricePoints(e?.currentTarget?.value);
                              }}
                              className="form-control"
                              w="100%"
                            />
                          </InputGroup>
                        </Box>
                      </>
                    )}

                    <Box
                      display="flex"
                      flexDir={['column', 'column', 'row', 'row']}
                      alignItems="center"
                      justifyContent="center"
                      w="100%"
                      mt="40px"
                      mb="40px"
                      key="Payment"
                    >
                      <Box
                        display="flex"
                        flexDir={['column', 'column']}
                        alignItems="center"
                        justifyContent="center"
                        w="100%"
                        key="Payment"
                      >
                        <Box display="flex" flexDir={['column', 'column', 'row', 'row']}>
                          <Box m="5px">
                            <Input
                              {...register('name', { required: true })}
                              autoComplete="off"
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Nome no Cartão"
                              required
                              onChange={e => handleInputChange(e, 'name')}
                              onFocus={() => handleInputFocus('name')}
                            />
                          </Box>
                          <Box m="5px">
                            <Input
                              {...register('number', { required: true })}
                              autoComplete="off"
                              type="tel"
                              name="number"
                              className="form-control"
                              placeholder="Número do Cartão"
                              pattern="[\d| ]{16,22}"
                              required
                              onChange={e => handleInputChange(e, 'number')}
                              onFocus={() => handleInputFocus('number')}
                            />
                          </Box>
                        </Box>
                        <Box display="flex" flexDir={['column', 'column', 'row', 'row']}>
                          <Box m="5px">
                            <Input
                              {...register('expiry', { required: true })}
                              autoComplete="off"
                              type="tel"
                              name="expiry"
                              className="form-control"
                              placeholder="Validade"
                              pattern="\d\d/\d\d"
                              required
                              onChange={e => handleInputChange(e, 'expiry')}
                              onFocus={() => handleInputFocus('expiry')}
                            />
                          </Box>
                          <Box m="5px">
                            <Input
                              {...register('cvc')}
                              autoComplete="off"
                              type="tel"
                              name="cvc"
                              className="form-control"
                              placeholder="CVC"
                              pattern="\d{3,4}"
                              required
                              onChange={e => handleInputChange(e, 'cvc')}
                              onFocus={() => handleInputFocus('cvc')}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box ml="5px" mt={['20px', '20px', '0px', '0px']} className="App-payment">
                        <CardPay
                          key="card_info_preview"
                          number={cardInfo.number}
                          name={cardInfo.name}
                          expiry={cardInfo.expiry}
                          cvc={cardInfo.cvc}
                          focused={cardInfo.focused}
                        />
                      </Box>
                    </Box>
                    {isPointsCard && (
                      <>
                        <Box
                          display="flex"
                          flexDir="column"
                          alignItems="flex-end"
                          justifyContent="flex-end"
                          w="100%"
                          mt="40px"
                          mb="40px"
                        >
                          <Text>Valor que será cobrado no cartão de crédito:</Text>
                          <Text>À vista, 1x parcela de </Text>
                          {isCotation === 'USD' ? (
                            <Text fontWeight="bold" fontSize="28px">
                              {`${typeCotationUSD()}`}
                            </Text>
                          ) : (
                            <Text fontWeight="bold" fontSize="28px">
                              {`${Math.ceil(parseFloat(priceCard)).toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}`}
                            </Text>
                          )}
                        </Box>
                        {priceCard > 0 ? (
                          <>
                            <Box mt="20px" display="flex" justifyContent="flex-end" flexDir="row">
                              <Text fontSize="26px" fontWeight="bold" mr="10px">
                                Total
                              </Text>
                              <Box display="flex" flexDir="row">
                                <Text fontSize="26px" fontWeight="bold">
                                  {pricePoints}
                                  {hotelDetails?.destination?.countryId === 'BR' ? ' Viajex N' : ' Viajex I'}
                                </Text>
                                <Image ml="5px" h="35px" src="/assets/icons/cash-club.png" />
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <Box mt="20px" display="flex" justifyContent="flex-end" flexDir="row">
                            <Text fontSize="26px" fontWeight="bold" mr="10px">
                              Total
                            </Text>
                            <Box display="flex" flexDir="row">
                              <Text fontSize="26px" fontWeight="bold">
                                {typeCotationCC()}
                                {hotelDetails?.destination?.countryId === 'BR' ? ' Viajex N' : ' Viajex I'}
                              </Text>
                              <Image ml="5px" h="35px" src="/assets/icons/cash-club.png" />
                            </Box>
                          </Box>
                        )}
                        <Box display="flex" alignItems="center" mt="20px" justifyContent="flex-end" flexDir="row">
                          <Text fontWeight="bold" mr="10px">
                            Saldo após a transação:
                          </Text>
                          <Box display="flex" flexDir="row">
                            <Text color="red">
                              {hotelDetails?.destination?.countryId === 'BR'
                                ? Math.floor(cashClubs?.saldoN) - typeCotationCC()
                                : Math.floor(cashClubs?.saldoI) - typeCotationCC()}
                              {hotelDetails?.destination?.countryId === 'BR' ? ' Viajex N' : ' Viajex I'}
                            </Text>
                            <Image ml="5px" h="25px" src="/assets/icons/cash-club.png" />
                          </Box>
                        </Box>
                      </>
                    )}
                    {isCard && (
                      <>
                        <Box
                          display="flex"
                          flexDir="column"
                          alignItems="flex-end"
                          justifyContent="flex-end"
                          w="100%"
                          mt="40px"
                          mb="40px"
                        >
                          <Text>Valor que será cobrado no cartão de crédito:</Text>
                          {doBookingPolice && doBookingPolice?.Rooms && doBookingPolice?.Rooms[0] && (
                            <Box mb="20px">
                              {isCotation === 'USD' ? (
                                <Select>
                                  <option>{`À vista, 1x parcela de  ${typeCotationUSD()}`}</option>
                                </Select>
                              ) : (
                                <Select>
                                  <option>{`À vista, 1x parcela de  ${Math.ceil(
                                    doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value,
                                  ).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}`}</option>
                                </Select>
                              )}
                            </Box>
                          )}
                        </Box>

                        <Box mt="20px" display="flex" justifyContent="flex-end" flexDir="row">
                          <Text fontSize="26px" fontWeight="bold" mr="10px">
                            Total
                          </Text>
                          {doBookingPolice && doBookingPolice?.Rooms && doBookingPolice?.Rooms[0] && (
                            <Box display="flex" flexDir="row">
                              <Text fontSize="26px" fontWeight="bold">
                                {`${Math.ceil(doBookingPolice?.Rooms[0]?.TotalSellingPrice?.Value).toLocaleString(
                                  'pt-br',
                                  {
                                    style: 'currency',
                                    currency: 'BRL',
                                  },
                                )}`}
                              </Text>
                            </Box>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}

              {Object.keys(errors)?.length !== 0 && (
                <Box alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                  <Alert status="error">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>Atenção!</AlertTitle>
                      <AlertDescription>
                        Preencha todos campos necessários de forma correta para prosseguir adiante
                      </AlertDescription>
                    </Box>
                  </Alert>
                </Box>
              )}
              {bookError && (
                <Box alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                  {' '}
                  <Alert status="error">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>A reserva não pode ser efetuada neste momento!</AlertTitle>
                      <AlertDescription>
                        Estornamos o seu pagamento, tente novamente mais tarde e veja a disponibilidade da sua reserva!
                      </AlertDescription>
                    </Box>
                  </Alert>
                </Box>
              )}
              {!bookError &&
                paymentData &&
                paymentData?.bill?.charges &&
                paymentData?.bill?.charges[0] &&
                paymentData?.bill?.charges[0]?.last_transaction?.gateway_message && (
                  <Box alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                    {paymentData &&
                    paymentData?.bill?.charges[0]?.last_transaction?.gateway_message.includes('Success') ? (
                      <Alert status="success">
                        <AlertIcon />
                        <Box>
                          <AlertTitle>
                            {paymentData?.bill?.charges[0]?.last_transaction?.gateway_message ??
                              'Pagamento efetuado com sucesso'}
                          </AlertTitle>
                          <AlertDescription>Pagamento efetuado com sucesso!</AlertDescription>
                        </Box>
                      </Alert>
                    ) : (
                      <Alert status="error">
                        <AlertIcon />
                        <Box>
                          <AlertTitle>
                            {paymentData?.bill?.charges[0]?.last_transaction?.gateway_message ??
                              'Ops! verifique os dados do cartão, limite e disponibilidade!'}
                          </AlertTitle>
                          <AlertDescription>Para mais auxílio, entre em contato com a Club Share!</AlertDescription>
                        </Box>
                      </Alert>
                    )}
                  </Box>
                )}
              {!bookError &&
                paymentData &&
                paymentData?.errors &&
                paymentData?.errors.map(err => (
                  <Box key={err?.message} alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                    <Alert status="error">
                      <AlertIcon />
                      <Box>
                        <AlertTitle>
                          {err?.message ?? 'Ops! verifique os dados do cartão, limite e disponibilidade!'}
                        </AlertTitle>
                        <AlertDescription>{'Para mais auxílio, entre em contato com a Club Share!'}</AlertDescription>
                      </Box>
                    </Alert>
                  </Box>
                ))}

              {errorPlayBook &&
                errorPlayBook?.data?.errors &&
                errorPlayBook?.data?.errors?.map(err => (
                  <Box key={err?.message} alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                    <Alert status="error">
                      <AlertIcon />
                      <Box>
                        <AlertTitle>
                          {err.message ?? 'Ops! verifique os dados do cartão, limite e disponibilidade!'}
                        </AlertTitle>
                        <AlertDescription>{'Para mais auxílio, entre em contato com a Club Share!'}</AlertDescription>
                      </Box>
                    </Alert>
                  </Box>
                ))}

              {doBooking !== [] && doBooking?.Rooms && doBooking?.Rooms[0] && doBooking?.Rooms[0]?.ReservationId && (
                <Box alignContent="center" alignItems="center" alignSelf="center" mt="20px">
                  <Alert status={doBooking?.Rooms && doBooking?.Rooms[0]?.ReservationId !== null ? 'success' : 'error'}>
                    <AlertIcon />
                    <Box>
                      <AlertTitle>
                        {doBooking?.Rooms && doBooking?.Rooms[0]?.ReservationId !== null
                          ? 'Parabéns! Reserva efetuada com sucesso'
                          : 'Ops! verifique os dados do cartão, limite e disponibilidade!'}
                      </AlertTitle>
                      <AlertDescription>
                        {doBooking?.Rooms && doBooking?.Rooms[0]?.ReservationId !== null
                          ? 'A Club Share agradece sua preferência!'
                          : 'Tente retornar mas tarde e efetuar uma nova reserva!'}
                      </AlertDescription>
                    </Box>
                  </Alert>
                </Box>
              )}

              {bookingId ? (
                <Box mt="100px" alignItems="center" justifyContent="center" display="flex" flexDir="row">
                  <Button
                    onClick={() =>
                      navigate('/', { replace: true, preventScrollReset: true, state: null, relative: 'path' })
                    }
                    w="60%"
                    colorScheme="green"
                  >
                    Reserva efetuada com sucesso! Clique aqui para voltar para o início
                  </Button>
                </Box>
              ) : (
                <Box
                  mt="100px"
                  alignItems="center"
                  justifyContent={['center', 'center', 'flex-end', 'flex-end']}
                  display="flex"
                  flexDir="row"
                >
                  <Button
                    disabled={Object.keys(errors)?.length !== 0}
                    type="submit"
                    w={['100%', '100%', '30%', '30%']}
                    colorScheme="green"
                  >
                    {loadingB ? 'Aguarde, processando sua reserva ... ' : 'Confirmar reserva'}
                    {loadingB ? (
                      <CircularProgress
                        size="15px"
                        isIndeterminate
                        color="white"
                        thickness="12px"
                        style={{ marginLeft: 10 }}
                      />
                    ) : (
                      <BsCheck style={{ marginLeft: 10 }} />
                    )}
                  </Button>
                </Box>
              )}
              <Box mt="50px" alignItems="center" justifyContent="center" display="flex" flexDir="row">
                {showCancelButton && (
                  <Button
                    onClick={() => openInNewTab()}
                    disabled={loadingCancel}
                    w="60%"
                    type="button"
                    colorScheme="red"
                  >
                    {loadingCancel ? 'Aguarde, processando cancelamento ... ' : 'Solicitar cancelamento'}
                  </Button>
                )}
              </Box>
            </Box>
            <Box justifySelf="flex-end" display="flex" flexDir="row" justifyContent="space-between">
              <Button onClick={() => prevStep()} variant="outline">
                <BsArrowLeft style={{ marginRight: 10 }} />
                Voltar
              </Button>
            </Box>
          </Box>
        );
    }
  };
  return <form onSubmit={handleSubmit(onSubmit)}>{switchTabs()}</form>;
}
